var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article, actions"}})],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-subheader',{staticClass:"text-h5 black--text"},[_vm._v(" Socio implementador ")])],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-form',{ref:"formActores",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","loading":_vm.ddSocioImplementadorLoading,"items":_vm.sociosImplementadores,"color":"blue-grey lighten-2","label":"Socio implementador","item-text":"socio","item-value":"id","rules":[_vm.selectRequired('Socio implmentador')],"no-data-text":_vm.sociosImplementadores.length > 0
                                    ? 'Seleccione un socio implmentador'
                                    : 'No se han encotrado socios implmentadores registrados',"menu-props":"auto"},model:{value:(_vm.datosSocio.socioImplementadorId),callback:function ($$v) {_vm.$set(_vm.datosSocio, "socioImplementadorId", $$v)},expression:"datosSocio.socioImplementadorId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12","xs":"12"}},[_c('v-textarea',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Descripción","rows":"4","maxlength":"2000"},model:{value:(_vm.datosSocio.descripcion),callback:function ($$v) {_vm.$set(_vm.datosSocio, "descripcion", $$v)},expression:"datosSocio.descripcion"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Monto en dólares","maxlength":"26","suffix":"US$"},model:{value:(_vm.montoMonedaOriginal),callback:function ($$v) {_vm.montoMonedaOriginal=$$v},expression:"montoMonedaOriginal"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{class:_vm.montoMonedaOriginal.length > 0 ? "required" : "",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Tipo de cambio","rules":[
                                    _vm.montoMonedaOriginal.length > 0 ? _vm.required('tipo de cambio') : true,
                                    _vm.montoMonedaOriginal.length > 0 ? _vm.decimals10('tipo de cambio') : true
                                ],"maxlength":"26"},model:{value:(_vm.tipoCambio),callback:function ($$v) {_vm.tipoCambio=$$v},expression:"tipoCambio"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12","xs":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","disabled":true,"autocomplete":"off","suffix":"GTQ","color":"blue-grey lighten-2","label":"Monto total quetzales","maxlength":"26"},model:{value:(_vm.datosSocio.montoTotalQuetzales),callback:function ($$v) {_vm.$set(_vm.datosSocio, "montoTotalQuetzales", $$v)},expression:"datosSocio.montoTotalQuetzales"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" Registrar socio ")])],1)],1)],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6  black--text"},[_vm._v(" Socios implementadores registrados ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersActores,"items":_vm.sociosImplementadoresAsignados,"loading":_vm.tableSociosLoading,"hide-default-footer":"","items-per-page":20,"footer-props":{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.socio))]),_c('td',[_vm._v(" "+_vm._s(item.descripcion))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.montoMonedaOriginal ? parseFloat(item.montoMonedaOriginal).toLocaleString("us-US", { style: 'currency', currency: 'USD' }) : "N/A"))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.tipoCambio ? item.tipoCambio : "N/A"))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.montoTotalQuetzales ? parseFloat(item.montoTotalQuetzales).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' }) : "N/A"))]),_c('td',[(item.estadosId==1)?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","disabled":_vm.btnEliminarDisabled,"loading":_vm.btnEliminarDisabled,"color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.eliminarItem(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar socio ")],1):_vm._e()],1)])]}}],null,false,2987925782)})],1)],1)],1)],1)],1):_vm._e(),_c('SnackAlert',{ref:"snackalert"}),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }