<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="!skeletonLoading">
      <v-form
        ref="form"
        v-on:submit.prevent="registrarItem"
        v-model="validForm"
      >
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-1">
            <v-subheader class="text-h5 black--text">
              Alcance del proyecto
            </v-subheader>
          </v-col>
        </v-row>

        <v-row class="pb-3" v-if="!seccionesBloqueadas">
          <v-col cols="12" md="12" sm="12">
            <v-alert outlined color="#1488c2" border="left">
              <p>
                Los campos marcados con un asterisco (<span
                  color="red"
                  class="red--text"
                  >*</span
                >) son obligatorios
              </p>
            </v-alert>
          </v-col>
        </v-row>

        <!--<v-row>

                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="datosItem.nombreProyecto"
                    label="Nombre del proyecto"
                    :rules="[
                        required('nombre del proyecto'),
                        minLength('nombre del proyecto', 5),
                    ]"
                    maxlength="250"
                    ></v-text-field>
                </v-col>
            </v-row>-->

        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-textarea
              dense
              filled
              autocomplete="off"
              class="required"
              color="blue-grey lighten-2"
              v-model="datosItem.descripcionProyecto"
              label="Descripción del proyecto"
              :rules="[
                required('descripción del proyecto'),
                minLength('descripción del proyecto', 10),
                maxLength('descripción del del proeycto', 2000)
              ]"
              maxlength="2000"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-textarea
              dense
              filled
              autocomplete="off"
              class=""
              color="blue-grey lighten-2"
              v-model="datosItem.antecedentes"
              label="Antecedentes del proyecto"
              :rules="[
                /*required('antecedentes del proyecto'),
                            minLength('antecedentes proyecto', 10),*/
                maxLength('antecedentes del proeycto', 2000)
              ]"
              maxlength="2000"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-textarea
              dense
              filled
              autocomplete="off"
              class=""
              color="blue-grey lighten-2"
              v-model="datosItem.problematica"
              label="Problemática del proyecto"
              :rules="[
                /*required('problematica del proyecto'),
                            minLength('problematica proyecto', 10),*/
                maxLength('problematica del proeycto', 2000)
              ]"
              maxlength="2000"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-textarea
              dense
              filled
              autocomplete="off"
              class=""
              color="blue-grey lighten-2"
              v-model="datosItem.justificacion"
              label="Justificación del proyecto"
              :rules="[
                /*required('justificación del proyecto'),
                            minLength('justificación proyecto', 10),*/
                maxLength('justificación del proeycto', 2000)
              ]"
              maxlength="2000"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-card class="mt-6" outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-subheader class="text-h6 black--text">
                  Situación del proyecto
                </v-subheader>
                <v-divider class="mt-0 pt-0"></v-divider>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-textarea
                  dense
                  filled
                  autocomplete="off"
                  class=""
                  color="blue-grey lighten-2"
                  v-model="datosItem.situacionSinProyecto"
                  label="Situación sin proyecto"
                  :rules="[
                    /*required('situación sin proyecto'),
                                    minLength('situación sin proyecto', 10),*/
                    maxLength('situación sin del proeycto', 2000)
                  ]"
                  maxlength="2000"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-textarea
                  dense
                  filled
                  autocomplete="off"
                  class=""
                  color="blue-grey lighten-2"
                  v-model="datosItem.situacionConProyecto"
                  label="Situación con proyecto"
                  :rules="[
                    /*required('situación con proyecto'),
                                    minLength('situación con proyecto', 10),*/
                    maxLength('situación con del proeycto', 2000)
                  ]"
                  maxlength="2000"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mt-6" outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-subheader class="text-h6 black--text">
                  Objetivos del proyecto
                </v-subheader>
                <v-divider class="mt-0 pt-0"></v-divider>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-textarea
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosItem.objetivoGeneral"
                  label="Objetivo general del proyecto"
                  :rules="[
                    required('objetivo general del proyecto'),
                    minLength('objetivo general del proyecto', 10),
                    maxLength('objetivo general del del proeycto', 2000)
                  ]"
                  maxlength="2000"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-textarea
                  dense
                  filled
                  autocomplete="off"
                  class=""
                  color="blue-grey lighten-2"
                  v-model="datosItem.objetivoEspecifico1"
                  label="Objetivo especifico 1 del proyecto"
                  :rules="[
                    /*required('objetivo especifico 1 del proyecto'),
                                    minLength('objetivo especifico 1 del proyecto', 10),*/
                    maxLength('objetivo especifico 1 del del proeycto', 2000)
                  ]"
                  maxlength="2000"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-textarea
                  dense
                  filled
                  autocomplete="off"
                  color="blue-grey lighten-2"
                  v-model="datosItem.objetivoEspecifico2"
                  label="Objetivo especifico 2 del proyecto"
                  :rules="[
                    /*required('objetivo especifico 1 del proyecto'),
                                    minLength('objetivo especifico 1 del proyecto', 10),*/
                    maxLength('objetivo especifico 1 del del proeycto', 2000),
                  ]"
                  maxlength="2000"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-textarea
                  dense
                  filled
                  autocomplete="off"
                  color="blue-grey lighten-2"
                  v-model="datosItem.objetivoEspecifico3"
                  label="Objetivo especifico 3 del proyecto"
                  :rules="[
                    /*required('objetivo especifico 1 del proyecto'),
                                    minLength('objetivo especifico 1 del proyecto', 10),*/
                    maxLength('objetivo especifico 1 del del proeycto', 2000)
                  ]"
                  maxlength="2000"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row class="mt-6" v-if="!seccionesBloqueadas || tipoUsuario == `int`">
          <v-col cols="12" md="12" sm="12">
            <v-spacer></v-spacer>
            <v-btn
              color="light-blue darken-2"
              class="white--text mb-2 float-right"
              type="submit"
              :elevation="0"
              :disabled="
                !validForm || (tipoUsuario == `ext` && seccionesBloqueadas)
              "
              :loading="btnRegistroLoading"
            >
              Guardar información
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-card class="mt-10" outlined>
        <v-card-text>
          <v-form
            ref="form2"
            v-on:submit.prevent="registrarIndicador"
            v-model="validFormIndicador"
          >
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                <v-subheader class="text-h6 black--text">
                  <!--Descripción de los cálculos y datos necesarios para obtener un valor cuantitativo del indicador-->
                  Indicadores del proyecto
                </v-subheader>
              </v-col>

              <v-col
                cols="12"
                md="12"
                sm="12"
                class="pt-0 pb-0"
                v-if="!seccionesBloqueadas || tipoUsuario == `int`"
              >
                <v-textarea
                  dense
                  filled
                  autocomplete="off"
                  class=""
                  color="blue-grey lighten-2"
                  v-model="datosIndicador.detalleIndicador"
                  label="Indicadores del proyecto"
                  :rules="[
                    required('indicadores del proyecto'),
                    minLength('indicadores del proyecto', 10),
                    maxLength('indicadores del proyecto', 2000)
                  ]"
                  maxlength="2000"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                >
                </v-textarea>
              </v-col>

              <v-col
                cols="12"
                md="12"
                sm="12"
                class="pt-0 mt-0"
                v-if="!seccionesBloqueadas || tipoUsuario == `int`"
              >
                <v-btn
                  color="primary"
                  class="mb-2 float-right align-bottom"
                  type="submit"
                  :elevation="0"
                  :disabled="
                    !validFormIndicador ||
                      (tipoUsuario == `ext` && seccionesBloqueadas)
                  "
                  :loading="btnRegistroIndicadorLoading"
                >
                  Agregar indicador
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <!--inicio:: tabla de documentos cargados -->
              <v-col cols="12" md="12" sm="12" class="pt-4">
                <v-data-table
                  class="elevation-1"
                  :headers="headersIndicadores"
                  :items="indicadores"
                  :loading="tableLoadingIndicadores"
                  hide-default-footer
                  no-data-text="No se encontraron registros"
                  loading-text="Cargando..."
                  :header-props="{
                    sortByText: 'Ordenar por'
                  }"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Registros por página',
                    pageText: '{0}-{1} de {2}',
                  }"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <!-- <td>{{ item.id }}</td> -->
                      <td>{{ item.detalleIndicador }}</td>
                      <td>
                        <v-btn
                          v-if="item.estadosId == 1"
                          class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                          small
                          depressed
                          :disabled="
                            btnEliminarIndicadorDisabled ||
                              (tipoUsuario == `ext` && seccionesBloqueadas)
                          "
                          color="blue-grey lighten-5"
                          @click="eliminarIndicador(item.id)"
                        >
                          <v-icon left>mdi-delete</v-icon> Eliminar
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <v-card class="mt-6" outlined>
        <v-card-text>
          <v-form
            ref="form2"
            v-on:submit.prevent="registrarRiesgo"
            v-model="validFormRiesgos"
          >
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                <v-subheader class="text-h6 black--text">
                  <!--Describa los riesgos del proyecto-->
                  Riesgos del proyecto
                </v-subheader>
              </v-col>

              <v-col
                cols="12"
                md="12"
                sm="12"
                class="pt-0 pb-0"
                v-if="!seccionesBloqueadas || tipoUsuario == `int`"
              >
                <v-textarea
                  dense
                  filled
                  autocomplete="off"
                  class=""
                  color="blue-grey lighten-2"
                  v-model="datosRiesgo.detalleRiesgo"
                  label="Describa los riegos del proyecto"
                  :rules="[
                    required('riesgos del proyecto'),
                    minLength('riesgos del proyecto', 10),
                    maxLength('riesgos del del proeycto', 2000)
                  ]"
                  maxlength="2000"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                >
                </v-textarea>
              </v-col>

              <v-col
                cols="12"
                md="12"
                sm="12"
                class="pt-0 mt-0"
                v-if="!seccionesBloqueadas || tipoUsuario == `int`"
              >
                <v-btn
                  color="primary"
                  class="mb-2 float-right align-bottom"
                  type="submit"
                  :elevation="0"
                  :disabled="
                    !validFormRiesgos ||
                      (tipoUsuario == `ext` && seccionesBloqueadas)
                  "
                  :loading="btnRegistroRiesgoLoading"
                >
                  Agregar riesgo
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <!--inicio:: tabla de documentos cargados -->
              <v-col cols="12" md="12" sm="12" class="pt-4">
                <v-data-table
                  class="elevation-1"
                  :headers="headersRiesgos"
                  :items="riesgos"
                  :loading="tableLoadingRiesgos"
                  hide-default-footer
                  no-data-text="No se encontraron registros"
                  loading-text="Cargando..."
                  :header-props="{
                    sortByText: 'Ordenar por'
                  }"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Registros por página',
                    pageText: '{0}-{1} de {2}',
                  }"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <!-- <td>{{ item.id }}</td> -->
                      <td>{{ item.detalleRiesgo }}</td>
                      <td>
                        <v-btn
                          v-if="item.estadosId == 1"
                          class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                          small
                          depressed
                          :disabled="
                            btnEliminarRiesgoDisabled ||
                              (tipoUsuario == `ext` && seccionesBloqueadas)
                          "
                          color="blue-grey lighten-5"
                          @click="eliminarRiesgo(item.id)"
                        >
                          <v-icon left>mdi-delete</v-icon> Eliminar
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <!--Inicio:: Snack alert-->
      <SnackAlert ref="snackalert"></SnackAlert>
      <!-- Fin:: Snack alert-->
    </div>
  </div>
</template>

<script>
import validations from "@/core/untils/validations.js";
import {
  REGISTRAR_PROYECTO_CNS_EXTERNO,
  OBTENER_DATOS_SECCIONES_PROYECTO
} from "@/core/services/store/proyectoscns/proyectocns.module";

import {
  OBTENER_RIESGOS_CNS,
  REGISTRAR_RIESGO_CNS,
  ELIMINAR_RIESGO_CNS
} from "@/core/services/store/proyectoscns/riesgos/riesgo.module";
import {
  OBTENER_INDICADORES_CNS,
  REGISTRAR_INDICADOR_CNS,
  ELIMINAR_INDICADOR_CNS
} from "@/core/services/store/proyectoscns/indicadores/indicador.module";

import SnackAlert from "@/view/content/alerts/SnackAlert.vue";

//import moment from "moment";

export default {
  name: "SeccionAlcanceProyecto",
  components: {
    SnackAlert,
  },
  props: ["id", "tipoUsuario", "confirmacion"], //Tipo de usuario puede ser ext (externo) o int (interno administrador), confirmacion (si se van a bloquear los elementos porque solo se van a visualizar)
  data() {
    return {
      skeletonLoading: false,
      seccionesBloqueadas: false,
      validForm: false,
      btnRegistroLoading: false,
      btnRegistroRiesgoLoading: false,
      btnRegistroIndicadorLoading: false,
      validFormRiesgos: false,
      validFormIndicador: false,
      riesgos: [],
      tableLoadingRiesgos: false,
      indicadores: [],
      tableLoadingIndicadores: false,
      datosItem: {
        antecedentes: "",
        problematica: "",
        justificacion: "",
        situacionSinProyecto: "",
        situacionConProyecto: "",
        objetivoGeneral: "",
        objetivoEspecifico1: "",
        objetivoEspecifico2: "",
        objetivoEspecifico3: "",
        descripcionProyecto: ""
      },
      datosIndicador: {
        detalleIndicador: "",
        proyectoCNSId: this.id,
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      datosRiesgo: {
        detalleRiesgo: "",
        proyectoCNSId: this.id,
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      btnEliminarIndicadorDisabled: false,
      btnEliminarRiesgoDisabled: false,
      ...validations,
    };
  },

  methods: {
    resetItems(proyectoId) {
      this.skeletonLoading = true;

      this.datosItem = {
        descripcionProyecto: "",
        nombreProyecto: "",
        antecedentes: "",
        problematica: "",
        justificacion: "",
        situacionSinProyecto: "",
        situacionConProyecto: "",
        objetivoGeneral: "",
        objetivoEspecifico1: "",
        objetivoEspecifico2: "",
        objetivoEspecifico3: "",
        indicadorProyecto1: "",
        indicadorProyecto2: "",
        indicadorProyecto3: "",
        riesgoProyecto1: "",
        riesgoProyecto2: "",
        riesgoProyecto3: ""
      };

      this.obtenerDatosItem(proyectoId);
    },

    async obtenerDatosItem(idProyecto) {
      this.datosItem = [];

      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: idProyecto,
        })
        .then(res => {
          if (res.status === 200) {
            this.datosItem = res.data;
            // console.log(this.datosItem)
            if (this.datosItem.estadoProyectoId != 1) {
              this.seccionesBloqueadas = true;
            } else {
              this.seccionesBloqueadas = false;
            }

            if (this.confirmacion) {
              this.seccionesBloqueadas = true;
            }

            this.datosItem.antecedentes
              ? (this.datosItem.antecedentes = this.datosItem.antecedentes)
              : (this.datosItem.antecedentes = "");
            this.datosItem.problematica
              ? (this.datosItem.problematica = this.datosItem.problematica)
              : (this.datosItem.problematica = "");
            this.datosItem.justificacion
              ? (this.datosItem.justificacion = this.datosItem.justificacion)
              : (this.datosItem.justificacion = "");
            this.datosItem.situacionSinProyecto
              ? (this.datosItem.situacionSinProyecto = this.datosItem.situacionSinProyecto)
              : (this.datosItem.situacionSinProyecto = "");
            this.datosItem.situacionConProyecto
              ? (this.datosItem.situacionConProyecto = this.datosItem.situacionConProyecto)
              : (this.datosItem.situacionConProyecto = "");
            this.datosItem.objetivoGeneral
              ? (this.datosItem.objetivoGeneral = this.datosItem.objetivoGeneral)
              : (this.datosItem.objetivoGeneral = "");
            this.datosItem.objetivoEspecifico1
              ? (this.datosItem.objetivoEspecifico1 = this.datosItem.objetivoEspecifico1)
              : (this.datosItem.objetivoEspecifico1 = "");
            this.datosItem.objetivoEspecifico2
              ? (this.datosItem.objetivoEspecifico2 = this.datosItem.objetivoEspecifico2)
              : (this.datosItem.objetivoEspecifico2 = "");
            this.datosItem.objetivoEspecifico3
              ? (this.datosItem.objetivoEspecifico3 = this.datosItem.objetivoEspecifico3)
              : (this.datosItem.objetivoEspecifico3 = "");
            this.datosItem.descripcionProyecto
              ? (this.datosItem.descripcionProyecto = this.datosItem.descripcionProyecto)
              : (this.datosItem.descripcionProyecto = "");
          }

          this.obtenerRiesgosProyecto();
          this.obtenerIndicadoresProyecto();
          this.skeletonLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.skeletonLoading = false;
          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    async obtenerRiesgosProyecto() {
      this.riesgos = [];
      this.tableLoadingRiesgos = true;

      await this.$store
        .dispatch(OBTENER_RIESGOS_CNS, { id: this.id })
        .then(res => {
          if (res.status === 200) {
            this.riesgos = res.data;
          }
          this.tableLoadingRiesgos = false;
        })
        .catch(error => {
          console.log(error);
          this.tableLoadingRiesgos = false;

          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    async obtenerIndicadoresProyecto() {
      this.indicadores = [];
      this.tableLoadingIndicadores = true;

      await this.$store
        .dispatch(OBTENER_INDICADORES_CNS, { id: this.id })
        .then(res => {
          if (res.status === 200) {
            this.indicadores = res.data;
          }
          this.tableLoadingIndicadores = false;
          this.skeletonLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.tableLoadingIndicadores = false;
          this.skeletonLoading = false;
          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    async registrarItem() {
      this.btnRegistroLoading = true;
      this.datosItem.id = 0;
      await this.$store
        .dispatch(REGISTRAR_PROYECTO_CNS_EXTERNO, {
          datos: this.datosItem,
          seccion: "Alcance",
          id: this.id,
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //  this.switchItemEstado = true;
            this.btnRegistroLoading = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroLoading = false;
        });
    },

    //Registrar los riesgos
    async registrarRiesgo() {
      this.btnRegistroRiesgoLoading = true;
      this.datosRiesgo.proyectoCNSId = this.id;
      await this.$store
        .dispatch(REGISTRAR_RIESGO_CNS, { datos: this.datosRiesgo })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //  this.switchItemEstado = true;
            this.datosRiesgo.detalleRiesgo = "";
            this.btnRegistroRiesgoLoading = false;
            this.obtenerRiesgosProyecto();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroRiesgoLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroRiesgoLoading = false;
        });
    },

    //Registrar Indicador del proyecto

    async registrarIndicador() {
      this.btnRegistroIndicadorLoading = true;
      this.datosIndicador.proyectoCNSId = this.id;
      await this.$store
        .dispatch(REGISTRAR_INDICADOR_CNS, { datos: this.datosIndicador })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //  this.switchItemEstado = true;
            this.datosIndicador.detalleIndicador = "";
            this.btnRegistroIndicadorLoading = false;
            this.obtenerIndicadoresProyecto();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroIndicadorLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroRiesgoLoading = false;
        });
    },

    ///Eliminar un indicador
    async eliminarIndicador(id) {
      this.btnEliminarIndicadorDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_INDICADOR_CNS, id)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerIndicadoresProyecto();
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarIndicadorDisabled = false;
        })
        .catch(error => {
          this.btnEliminarIndicadorDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    ///Eliminar un riesto
    async eliminarRiesgo(id) {
      this.btnEliminarRiesgoDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_RIESGO_CNS, id)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerRiesgosProyecto(this.id);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarRiesgoDisabled = false;
        })
        .catch(error => {
          this.btnEliminarRiesgoDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    }
  },

  created() {
    this.resetItems(this.id);
    //this.obtenerDatosItem(this.id);
  },

  computed: {
    headersRiesgos() {
      {
        return [
          /* {
                    text: "Id",
                    align: "start",
                    sortable: false,
                    value: "id"
                }, */
          {
            text: "Detalle del riesgo",
            align: "start",
            sortable: false,
            value: "detalleRiesgo"
          },
          {
            text: "Acciones",
            align: "start",
            sortable: true,
            value: "nombreMoneda"
          }
        ];
      }
    },

    headersIndicadores() {
      return [
        /*   {
                    text: "Id",
                    align: "start",
                    sortable: false,
                    value: "id"
                }, */
        {
          text: "Detalle del indicador",
          align: "start",
          sortable: false,
          value: "detalleRiesgo"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        }
      ];
    }
  }
};
</script>
