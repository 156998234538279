<template>

<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>
    
    <div v-if="!skeletonLoading">
        <v-row>
            <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <v-subheader class="text-h5 black--text">
                    Socio implementador
                </v-subheader>
            </v-col>
        </v-row>

        <!--inicio:: condiciones de beneficiarios -->
        <v-card class="mt-6" outlined>
            <v-card-text>
               
                <v-form 
                    ref="formActores"
                    v-on:submit.prevent="registrarItem"
                    v-model="validForm"
                    >


                    <v-row class="mt-4">

                        <!--inicio:: campo -->
                        <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="datosSocio.socioImplementadorId"
                                :loading="ddSocioImplementadorLoading"
                                :items="sociosImplementadores"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Socio implementador"
                                item-text="socio"
                                item-value="id"
                                :rules="[selectRequired('Socio implmentador')]"
                                :no-data-text="
                                    sociosImplementadores.length > 0
                                    ? 'Seleccione un socio implmentador'
                                    : 'No se han encotrado socios implmentadores registrados'
                                "
                                menu-props="auto"
                            >
                            </v-select>
                        </v-col>
                        <!--fin:: campo-->
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12" sm="12" xs="12"  class="pt-0 pb-0">
                            <v-textarea
                                dense
                                filled
                                autocomplete="off"
                                class=""
                                color="blue-grey lighten-2"
                                v-model="datosSocio.descripcion"
                                label="Descripción"
                                rows="4"
                                maxlength="2000"
                            ></v-textarea>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class=""
                                color="blue-grey lighten-2"
                                v-model="montoMonedaOriginal"
                                label="Monto en dólares"
                                maxlength="26"
                                suffix="US$"
                            ></v-text-field>
                        </v-col>


                        <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                :class="montoMonedaOriginal.length > 0 ? `required` : ``"
                                color="blue-grey lighten-2"
                                v-model="tipoCambio"
                                label="Tipo de cambio"
                                :rules="[
                                    montoMonedaOriginal.length > 0 ? required('tipo de cambio') : true,
                                    montoMonedaOriginal.length > 0 ? decimals10('tipo de cambio') : true
                                ]"
                                maxlength="26"
                            ></v-text-field>
                        </v-col>


                        <v-col cols="12" md="4" sm="12" xs="12"  class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                :disabled="true"
                                autocomplete="off"
                                class="required"
                                suffix="GTQ"
                                color="blue-grey lighten-2"
                                v-model="datosSocio.montoTotalQuetzales"
                                label="Monto total quetzales"
                                maxlength="26"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            
                            <v-btn
                                color="light-blue-502"
                                class="white--text mb-2 float-right"
                                type="submit"
                                :elevation="0"
                                :disabled="!validForm"
                                :loading="btnRegistroLoading"
                                
                            >
                                Registrar socio
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>

                <v-row class="mt-6">
                    <v-col cols="12" md="12" sm="12">
                        <v-subheader class="text-h6  black--text">
                            Socios implementadores registrados 
                        </v-subheader>
                        <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                </v-row>

                <v-row>
                    <!--inicio:: tabla de documentos cargados -->
                    <v-col cols="12" md="12" sm="12" class="pt-4">
                        <v-data-table
                            class="elevation-1"
                            :headers="headersActores"
                            :items="sociosImplementadoresAsignados"
                            :loading="tableSociosLoading"
                            hide-default-footer
                            :items-per-page="20"
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }"
                            >

                                <template v-slot:item="{ item }">
                                    <tr>
                                        <!-- <td>{{ item.municipio ? item.municipio + ", " :  ""}} {{item.departamento}}</td> -->
                                        <td>{{ item.socio }}</td>
                                        <td> {{ item.descripcion }}</td>
                                        <td class="text-right">{{ item.montoMonedaOriginal ? parseFloat(item.montoMonedaOriginal).toLocaleString("us-US", { style: 'currency', currency: 'USD' }) : `N/A` }}</td>
                                        <td class="text-right">{{ item.tipoCambio ? item.tipoCambio : "N/A"  }}</td>
                                        <td class="text-right">{{ item.montoTotalQuetzales  ? parseFloat(item.montoTotalQuetzales).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' }) : `N/A` }}</td>
                                        <!--<td>
                                            <v-chip
                                                class="ma-2 font-weight-medium"
                                                label
                                                :color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-4'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-4'
                                                    : item.estadosId === 6
                                                    ? 'cyan lighten-4'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-4'
                                                    : 'pink lighten-4'
                                                "
                                                :text-color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-1'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-1'
                                                    : item.estadosId === 6
                                                    ? 'cyan darken-1'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-1'
                                                    : 'pink darken-1'
                                                "
                                                small
                                                >
                                                {{ item.estado }}
                                            </v-chip>
                                        </td>-->
                                        <!-- <td> {{parseFloat(item.montoSuscrito).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}}</td> -->
                                        <td>
                                            <v-btn v-if="item.estadosId==1"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                :disabled="btnEliminarDisabled"
                                                :loading="btnEliminarDisabled"
                                                color="blue-grey lighten-5"
                                                @click="eliminarItem(item.id)"
                                                >
                                                <v-icon left>mdi-delete</v-icon> Eliminar socio
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                  </v-row>


            </v-card-text>
        </v-card>

    </div>

    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->

</div>

</template>


<script>

import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
//import { OBTENER_DATOS_SECCIONES_PROYECTO } from "@/core/services/store/proyectoscns/proyectocns.module";
import {OBTENER_PROYECTO_CNSCOO} from "@/core/services/store/proyectoscooperantes/proyectocooperantes.module";
import { OBTENER_SOCIOS_IMPLEMENTADORES_CSS } from "@/core/services/store/sociosimplementadores/socioimplementador.module";

import {OBTENER_CNSC_ACTORES_IMPLEMENTADORES, REGISTRAR_CNSC_ACTOR_IMPLEMENTADOR, ELIMINAR_CNSC_ACTOR_IMPLEMENTADOR } from "@/core/services/store/proyectoscooperantes/actoresimplementadorescooperante/actorimplementadorcooperante.module";


export default {
    name: "SeccionActorImplementadorPoryectoCooperante",
    props: ['id', 'tipo', 'datosGeneralesProyecto'], //Tipo 1 segeplan (solo visualizar), tipo 2 externo (visualizar y editar)
    components: {
        DialogLoader,
        SnackAlert
    },
    data() {
        return {
            skeletonLoading: false,
            actoresRegistrados: [],
            tableLoading: false,
            validForm: false,
            btnRegistroLoading: false,
            btnEliminarDisabled: false,
            datosProyecto: {
                estadosId: 1,
                usuarioCreacion: "admin"
            },
            ddSocioImplementadorLoading: false,
            tableSociosLoading: false,
            sociosImplementadores:[],
            ddSociosImplementadoresLoading: false,
            sociosImplementadoresAsignados: [],
            datosSocio: {
                socioImplementadorId: 0,
                proyectoCNSCId: this.id,
                descripcion: "",
                montoMonedaOriginal: "",
                tipoCambio: "",
                montoTotalQuetzales: "",
                estadosId: 1,
                usuarioCreacion: "admin"
            },
            montoMonedaOriginal: "",
            tipoCambio: "",
            ...validations
        }
    },

    methods:{

        resetItems(tipo){
            
            
            this.resetForm();


            return tipo;
        },

        resetForm(){
            this.montoMonedaOriginal="";
            this.tipoCambio = "";

            this.datosSocio = {
                socioImplementadorId: 0,
                proyectoCNSCId: this.id,
                descripcion: "",
                montoUSD: "",
                tipoCambio: "",
                montoTotalQuetzales: "",
                estadosId: 1,
                usuarioCreacion: "admin"
            };
        },

        //Obtener la información de cobertura
        async obtenerDatosProyecto(proyectoId){

            this.datosProyecto = {};
            this.skeletonLoading = true;
         
            this.$store
                .dispatch(OBTENER_PROYECTO_CNSCOO, {id: proyectoId})
                .then(res => {
                    if(res.status===200){
                        

                        this.obtenerSociosImplementadoresAsignados(proyectoId);
                    /*     this.datosProyecto = res.data;
                        if(this.datosProyecto.cuentaInstitucionesBeneficiarias){
                            this.cuentaInstitucionesBeneficiariasSel = this.institucionesBeneficiariasItems.find(item => item.id ===this.datosProyecto.cuentaInstitucionesBeneficiarias);
                            if(this.datosProyecto.cuentaInstitucionesBeneficiarias===1){
                                this.habilitarRegistroInstituciones = true;
                            }
                        } else{
                            this.cuentaInstitucionesBeneficiariasSel = {
                                id: 0, text: 'Seleccione'
                            }
                        }

                      //  console.log(this.datosProyecto.beneficiariosDirectos)

                        if(this.datosProyecto.cuentaBeneficiariosDirectos){
                            this.beneficiarioDirectoSel = this.beneficiariosDirectosItems.find(item => item.id ===this.datosProyecto.cuentaBeneficiariosDirectos);
                            if(this.datosProyecto.cuentaBeneficiariosDirectos===1){
                                this.habilitarRegistroBeneficiarios = true;
                            }
                        } else{
                            this.beneficiarioDirectoSel = {
                                id: 0, text: 'Seleccione'
                            }
                        }
 */
                       
                        
                    }
                   
                    this.skeletonLoading=false;
                    this.condicionDesembolsos.id = this.datosProyecto.condicionesDesembolsos;
                })
                .catch(() => {
                    this.skeletonLoading=false;
                    this.cardDatosLoading = false;
                });

        },


        //Obtener actores implementadores
        async obtenerSociosImplementadores(){
            
            this.ddSociosImplementadoresLoading = true;
            this.sociosImplementadores = [];
            await this.$store
                .dispatch(OBTENER_SOCIOS_IMPLEMENTADORES_CSS, {estadoId: 1})
                .then(res => {
                    if (res.status === 200) {
                        this.sociosImplementadores = res.data;
                    }
                    this.ddSociosImplementadoresLoading = false;
                })
                .catch(() => {
                    this.sociosImplementadores = [];
                    this.ddSociosImplementadoresLoading = false;
                });
        },


        //OBtener los socios implementadores asignados al proyecto
        async obtenerSociosImplementadoresAsignados(proyectoId){
            this.tableSociosLoading = true;
            this.sociosImplementadoresAsignados = [];
            await this.$store
                .dispatch(OBTENER_CNSC_ACTORES_IMPLEMENTADORES, {estadoId: 1, proyectoId: proyectoId})
                .then(res => {
                    if (res.status === 200) {
                        this.sociosImplementadoresAsignados = res.data;
                    }
                    this.tableSociosLoading = false;
                })
                .catch(() => {
                    this.sociosImplementadoresAsignados = [];
                    this.tableSociosLoading = false;
                });
            
        },


        async registrarItem(){
            this.datosSocio.tipoCambio = parseFloat(this.tipoCambio);
            
            this.btnRegistroLoading = true;

            if(this.montoMonedaOriginal.length <= 0 || this.montoMonedaOriginal == null){
                this.datosSocio.montoMonedaOriginal = null; 
                this.datosSocio.montoTotalQuetzales = null;
                this.datosSocio.tipoCambio = null;
            } else {
                this.datosSocio.montoMonedaOriginal = parseFloat(this.montoMonedaOriginal);
            }

            /* if(this.datosBeneficiario.cantidadBeneficiarios <= 0){
                this.$refs.snackalert.SnackbarShow("warning","Alerta",`La cantidad de beneficiarios debe ser mayor a 0.`);
            } */

            //Validar municipio
           /*  if(!this.datosBeneficiario.municipiosId || this.datosBeneficiario.municipiosId === 0)
            {
                delete this.datosBeneficiario.municipiosId;
            }  */


           /*  this.datosBeneficiario.genero = this.generoSeleccionado.codigo;
 */
            await this.$store
            .dispatch(REGISTRAR_CNSC_ACTOR_IMPLEMENTADOR, {datos: this.datosSocio})
            .then(res => {
                this.btnLoading = false;
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);
                    this.obtenerSociosImplementadoresAsignados(this.id);
                    this.resetForm();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
                }
                this.btnRegistroLoading = false;
            })
            .catch(() => {
                this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro.`);
                this.btnRegistroLoading = false;
            });
            //this.btnRegistroBeneficiarioLoading = false;
            //return 0;
        },

        //Eliminar registro de cobertura
        async eliminarItem(id){
            this.btnEliminarDisabled = true;

            await this.$store
            .dispatch(ELIMINAR_CNSC_ACTOR_IMPLEMENTADOR, id)
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerSociosImplementadoresAsignados(this.id);             
                   // this.resetItems();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnEliminarDisabled=false;
            })
            .catch(error => {
                
                 this.btnEliminarDisabled=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        },
    },

    created(){
        this.obtenerDatosProyecto(this.id);
        this.obtenerSociosImplementadores();
    },

     watch:{

        montoMonedaOriginal : function(){
            (this.tipoCambio.length > 0 && this.montoMonedaOriginal.length > 0) ? this.datosSocio.montoTotalQuetzales = (parseFloat(this.montoMonedaOriginal) * parseFloat(this.tipoCambio)) : this.datosSocio.montoTotalQuetzales="";
        },

        tipoCambio : function() {
             (this.tipoCambio.length > 0 && this.montoMonedaOriginal.length > 0) ? this.datosSocio.montoTotalQuetzales = (parseFloat(this.montoMonedaOriginal) * parseFloat(this.tipoCambio)) : this.datosSocio.montoTotalQuetzales="";
        },

    },


    computed: {

        headersActores(){
            return [
                 {
                    text: "Id",
                    //align: "start",
                    sortable: false,
                    value: "id",
                    align: ' d-none'
                },
               /*  {
                    text: "Municipio / Departamento",
                    align: "start",
                    sortable: true,
                    value: "nombreMunicipio"
                }, */
                {
                    text: "Socio Implementador",
                    align: "start",
                    sortable: true,
                    value: "socio"
                },
                {
                    text: "Descripción",
                    align: "start",
                    sortable: true,
                    value: "descripcion"
                },
                {
                    text: "Monto dólares",
                    align: "start",
                    sortable: true,
                    value: "montoMonedaOriginal"
                },
                {
                    text: "Tipo de cambio",
                    align: "start",
                    sortable: true,
                    value: "tipoCambio"
                },
                {
                    text: "Monto quetzales",
                    align: "start",
                    sortable: true,
                    value: "montoTotalQuetzales"
                },
               /*  {
                    text: "Monto suscrito",
                    align: "start",
                    sortable: true,
                    value: "monto"
                }, */
                {
                    text: "Acciones",
                    align: "start",
                    sortable: true,
                    value: ""
                },
            ]
        }
    }
}

</script>