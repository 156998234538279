<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-if="!skeletonLoading">
      <v-form
        ref="form"
        v-on:submit.prevent="registrarItem"
        v-model="validForm"
      >
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
            <v-subheader class="text-h5 black--text">
              Información general del programa/proyecto/acción
            </v-subheader>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-textarea
              dense
              filled
              autocomplete="off"
              class="required"
              color="blue-grey lighten-2"
              v-model="datosItem.nombreProyecto"
              label="Nombre del programa/proyecto/acción"
              :rules="[
                required('Nombre del programa/proyecto/acción'),
                minLength('Nombre del programa/proyecto/acción', 5),
                maxLength('Nombre del programa/proyecto/acción', 500)
              ]"
              rows="3"
              maxlength="500"
              :disabled="
                (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
              "
            ></v-textarea>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0 mt-2">
            <v-select
              v-model="datosItem.estadoProyectoId"
              :items="estadosProyecto"
              :loading="false"
              dense
              :disabled="false"
              class="required"
              filled
              label="Estado del proyecto"
              item-value="id"
              :no-data-text="
                sectoresBeneficiados != null
                  ? 'Seleccione un estado para el proyecto'
                  : 'No se han encontrado estados para el proyecto'
              "
              menu-props="offset-y"
            ></v-select>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-autocomplete
              v-model="datosItem.entidadId"
              :items="instituciones"
              :loading="ddInstitucionesLoading"
              dense
              filled
              class="required"
              label="Institución"
              :item-text="
                item =>
                  item.sigla
                    ? `${item.nombreInstitucion} (${item.sigla})`
                    : item.nombreInstitucion
              "
              item-value="id"
              :no-data-text="
                instituciones != null
                  ? 'Selecciona una institución'
                  : 'No se han encontrado instituciones'
              "
              @change="
                obtenerUnidadesEjecutorasPorInstitucion(datosItem.entidadId)
              "
              menu-props="offset-y"
              :rules="[selectRequired('institución')]"
              :disabled="
                (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
              "
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0"> <!--v-if="mostrarUnidadesEjecutoras"-->
            <v-select
              v-model="datosItem.unidadEjecutoraId"
              :items="unidadesEjecutoras"
              :loading="unidadesEjecutorasLoading"
              dense
              :clearable="true"
              filled
              class=""
              label="Unidad Ejecutora"
              item-text="unidadEjecutora"
              item-value="id"
              :no-data-text="
                unidadesEjecutoras.length > 0
                  ? 'Selecciona una unidad ejecutora'
                  : 'No se han encontrado unidades ejecutoras'
              "
              menu-props="offset-y"
              :disabled="
                (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
              "
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0 mt-2">
            <v-select
              v-model="datosItem.sectoresId"
              :items="sectoresBeneficiados"
              :loading="false"
              dense
              class="required"
              filled
              label="Sector principal al que aporta"
              item-text="sector"
              item-value="id"
              :no-data-text="
                sectoresBeneficiados != null
                  ? 'Seleccione un sector beneficiado'
                  : 'No se han encontrado sectores beneficiado'
              "
              menu-props="offset-y"
              :disabled="
                (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
              "
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
            <v-menu
              ref="menuFechaSuscripcionDonacion"
              v-model="menuFechaSuscripcionDonacion"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="computedDateFormattedSuscripcion"
                  readonly
                  label="Fecha de suscripción de la donación"
                  hint="DD/MM/AAAA"
                  persistent-hint
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[
                    required('Fecha de suscripción de la donación'),
                    dateFormat('Fecha de suscripción de la donación')
                  ]"
                  v-mask="'##/##/####'"
                  maxlength="10"
                  :disabled="
                    (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateSuscripcion"
                no-title
                @input="menuFechaSuscripcionDonacion = false"
                locale="es"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!--
            :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
          -->

          <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
            <v-menu
              ref="menuFechaInicio"
              v-model="menuFechaInicio"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="computedDateFormattedInicio"
                  readonly
                  label="Fecha de inicio"
                  hint="DD/MM/AAAA"
                  persistent-hint
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[
                    required('Fecha de inicio'),
                    dateFormat('Fecha de inicio')
                  ]"
                  v-mask="'##/##/####'"
                  maxlength="10"
                  :disabled="
                    (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateInicio"
                no-title
                @input="menuFechaInicio = false"
                locale="es"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
            <v-menu
              ref="menuFechaFinalizacion"
              v-model="menuFechaFinalizacion"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="computedDateFormattedFinalizacion"
                  readonly
                  label="Fecha de finalización"
                  hint="DD/MM/AAAA"
                  persistent-hint
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[
                    required('Fecha de finalización'),
                    dateFormat('Fecha de finalización')
                  ]"
                  v-mask="'##/##/####'"
                  maxlength="10"
                  :disabled="
                    (tipoUsuario == `ext` && seccionesBloqueadas) ||
                      confirmacion
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFin"
                no-title
                @input="menuFechaFinalizacion = false"
                locale="es"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-textarea
              dense
              filled
              autocomplete="off"
              class="required"
              color="blue-grey lighten-2"
              v-model="datosItem.objetivoGeneral"
              label="Objetivo general"
              :rules="[
                required('objetivo general'),
                minLength('objetivo general', 10),
                maxLength('objetivo general', 2000)
              ]"
              maxlength="2000"
              :disabled="
                (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
              "
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-textarea
              dense
              filled
              autocomplete="off"
              class="required"
              color="blue-grey lighten-2"
              v-model="datosItem.resultados"
              label="Resultados esperados"
              :rules="[
                required('Resultados esperados'),
                minLength('Resultados esperados', 10),
                maxLength('Resultados esperados', 2000)
              ]"
              maxlength="2000"
              :disabled="
                (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
              "
            >
            </v-textarea>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
            <v-select
              v-model="tipoCooperacionSeleccionada"
              :items="tiposCooperacion"
              :loading="false"
              dense
              filled
              class="required"
              label="Tipo de cooperación"
              item-text="nombreTipoCooperacion"
              item-value="id"
              return-object
              :no-data-text="
                tiposCooperacion != null
                  ? 'Seleccione un tipo de cooperación'
                  : 'No se han encontrado tipos de cooperación'
              "
              menu-props="offset-y"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            ></v-select>
          </v-col>
        </v-row> -->

        <v-card class="mt-6 elevation-0" outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-subheader class="text-h5 font-weight-bolder text-dark">
                  Documentos del proyecto
                </v-subheader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8" sm="7" xs="12" class="pt-4 pb-0">
                <template>
                  <v-file-input
                    id="respaldoFile"
                    dense
                    filled
                    placeholder="Seleccionar documento que respalda el proyecto"
                    label="Documento de respaldo del proyecto"
                    append-icon="mdi-file-pdf-outline"
                    prepend-icon=""
                    accept="application/pdf,image/jpeg"
                    @change="onFileChange('respaldo', $event)"
                    :rules="[
                      accion === 1
                        ? fileRequired('Documento de respaldo del proyecto')
                        : true
                    ]"
                    :show-size="1000"
                    ref="respaldoFile"
                    :class="accion === 1 ? `required` : ``"
                    :disabled="
                      (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                    "
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" small label color="primary">
                        {{ text }}
                      </v-chip>
                      <span
                        v-else-if="index === 2"
                        class="
                            text-overline
                            grey--text
                            text--darken-3
                            mx-2
                            "
                      >
                        +{{ files.length - 2 }} archivo(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>

              <v-col
                cols="12"
                md="4"
                sm="5"
                xs="12"
                class="pt-6 pb-6"
                v-if="documentoRespaldoRegistrado == true && accion === 2"
              >
                <v-btn
                  color="light-blue-502"
                  class="mb-1 float-right white--text"
                  depressed
                  @click="descargarArchivo(datosItem.pathRespaldoProyecto)"
                >
                  <v-icon left>mdi-download</v-icon> Descargar
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="8" sm="7" xs="12" class="pt-0 pb-0">
                <template>
                  <v-file-input
                    id="suscripcionFile"
                    dense
                    filled
                    placeholder="Seleccionar documento de suscripción"
                    label="Documento de suscripción"
                    append-icon="mdi-file-pdf-outline"
                    prepend-icon=""
                    accept="application/pdf,image/jpeg"
                    @change="onFileChange('suscripcion', $event)"
                    :rules="[
                      accion === 1
                        ? fileRequired('Documento de suscripción')
                        : true
                    ]"
                    :show-size="1000"
                    ref="suscripcionFile"
                    :class="accion === 1 ? `required` : ``"
                    :disabled="
                      (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                    "
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" small label color="primary">
                        {{ text }}
                      </v-chip>
                      <span
                        v-else-if="index === 2"
                        class="
                            text-overline
                            grey--text
                            text--darken-3
                            mx-2
                            "
                      >
                        +{{ files.length - 2 }} archivo(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>

              <v-col
                cols="12"
                md="4"
                sm="5"
                xs="12"
                class="pt-6 pb-6"
                v-if="documentoSuscripcionRegistrado == true && accion === 2"
              >
                <v-btn
                  color="light-blue-502"
                  class="mb-1 float-right white--text"
                  depressed
                  @click="descargarArchivo(datosItem.pathDocumentoSuscripcion)"
                >
                  <v-icon left>mdi-download</v-icon> Descargar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row class="mt-2" v-if="!seccionesBloqueadas || tipoUsuario == `int`">
          <v-col cols="12">
            <v-btn
              color="light-blue darken-2"
              class="white--text mb-2 float-right"
              type="submit"
              :elevation="0"
              :disabled="
                !validForm || (tipoUsuario == `ext` && seccionesBloqueadas)
              "
              :loading="btnRegistroLoading"
            >
              {{ accion === 1 ? `Registrar información` : `Actualizar información` }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="tipo === 2">
          <v-col cols="12">
            <v-btn
              color="light-blue-502"
              class="white--text mb-2 float-right mt-2"
              type="submit"
              :elevation="0"
              :disabled="!validForm"
              :loading="btnRegistroLoading"
            >
              {{ btnRegistroText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <!--Inicio:: Snack alert-->
      <SnackAlert ref="snackalert"></SnackAlert>
      <!-- Fin:: Snack alert-->
    </div>
  </div>
</template>

<script>
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import DownloadFile from "@/core/untils/downloadfile.js";
import moment from "moment";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
//import { OBTENER_UNIDADES_EJECUTORAS_INSTITUCION } from "@/core/services/store/unidadesejecutoras/unidadejecutora.module";
import { OBTENER_UNIDADES_EJECUTORAS_ENTIDAD } from "@/core/services/store/unidadesejecutoras/unidadejecutora.module";
import {
  OBTENER_ITEMS_CATALOGO,
  OBTENER_SUBITEMS_CATALOGO,
} from "@/core/services/store/catalogos/catalogointerno.module";

import { OBTENER_PROYECTO_CDES } from "@/core/services/store/proyectoscooperaciondescentralizada/proyectocooperaciondescentralizada.module";

import { OBTENER_AGENCIAS_MULTILATERALES } from "@/core/services/store/catalogos/agenciasmultilaterales/agenciamultilateral.module";
import { REGISTRAR_PROYECTO_CDES, ACTUALIZAR_DATOS_GENERALES_PROYECTO_CDES } from "@/core/services/store/proyectoscooperaciondescentralizada/proyectocooperaciondescentralizada.module";

export default {
  name: "SeccionDatosGeneralesProyectosDescentralizada",
  components: {
    SnackAlert
  },
  props: ["id", "tipoUsuario", "confirmacion", "accion"], //Tipo de usuario puede ser ext (externo) o int (interno administrador), confirmacion (si se van a bloquear los elementos porque solo se van a visualizar)
  data() {
    return {
      seccionesBloqueadas: false,
      dateSuscripcion: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateInicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      skeletonLoading: false,
      validForm: false,
      btnRegistroText: "Registrar información",
      btnRegistroLoading: false,
      instituciones: [],
      ddInstitucionesLoading: false,
      unidadesEjecutorasLoading: false,
      unidadesEjecutoras: [],
      unidadEjecutoraSeleccionada: {},
      instrumentos: [],
      instrumentoSeleccionado: {},
      tiposInstrumentos: [],
      tiposInstrumentoSeleccionado: {},
      ddTiposInstrumentoLoading: false,
      cambiarFuenteCooperante: 1,
      cambiarFuenteCooperanteItems: [
        { id: 1, text: "No" },
        { id: 2, text: "Si" },
      ],
      programaConjunto: 1,
      programaConjuntoItems: [
        { id: 1, text: "No" },
        { id: 2, text: "Si" },
      ],
      tiposFuenteCooperante: [],
      tipoFuenteCooperanteSeleccionada: {},
      paisesFuente: [],
      fuentesBilaterales: [],
      fuenteBilateralSeleccionada: {},
      fuentesMultilateral: [],
      fuenteMultilateralSeleccionada: {},
      agencias: [],
      agenciaSeleccionada: {},
      tiposCooperacion: [],
      tipoCooperacionSeleccionada: {},
      modalidadesEjecucion: [],
      modalidadCooperacionSeleccionada: {},

      modalidadEjecucionSeleccionada: {},
      tiposAprobacion: [],
      tipoAprobacionSeleccionada: {},
      menuFechaSuscripcionDonacion: false,
      menuFechaInicio: false,
      menuFechaFinalizacion: false,
      //fechaSuscripcionDonacion: '',
      //fechaInicio: '',
      //fechaFinalizacion: '',

      modalidadesEjecucionSel: 0,
      ingresaAPresupuestoItems: [
        { id: 1, text: "Si" },
        { id: 2, text: "No" },
      ],
      estadosProyecto: [
        { id: 1, text: "Documento suscrito (formalizado)" },
        { id: 2, text: "Ejecución" },
        { id: 3, text: "Suspendido" },
        { id: 4, text: "Finalizado" },
      ],
      ingresaAPresupuestoSeleccionado: {
        id: 2,
        text: "No",
      },
      fechaSuscripcionDonacion: "",
      fechaInicio: "",
      fechaFinalizacion: "",
      datosItem: {
        nombreProyecto: "",
        fechaSolicitudOpinionTecnica: "",
        unidadesEjecutorasId: 0,
        tiposInstrumentoId: 0,
        tiposCooperacionId: 0,
        modalidadEjecucionId: 0,
        cambiarFuenteCooperante: 1,
        tiposFuenteCooperanteId: 0,
        tiposAprobacionDonacionId: 0,
        numeroDonaciones: "",
        ingresaAPresupuesto: 0,
        codigoFuenteCooperante: "",
        codigoDelProyecto: "",
        codigoInstitucion: "",
        fuentesMultilateralesId: null,
        fuentesBilateralesId: null,
        cooperantesCNSCId: this.cooperanteId,
        agenciasId: null,
        agenciaMultilateralId: null,
        objetivoGeneral: "",
        resultados: "",
        estadoProyectoId: 0,
      },
      agenciasMultilaterales: [],
      ddAgenciasMultiLoading: false,

      tipoFuenteUsuario: {},
      fuentesBilateralesId: 0,
      agenciasId: 0,

      datosCooperacion: {
        monto: 0,
      },
      ddAgenciasLoading: false,
      documentoRespaldoReemplazado: false,
      documentoSuscripcionReemplazado: false,
      documentoRespaldo: [],
      documentoRespaldoRegistrado: false,
      documentoSuscripcionRegistrado: false,
      archivoRespaldo: null,
      archivoSuscripcion: null,
      archivosCargado: false,
      ...validations
    };
  },

  methods: {
    resetItems(accion, proyectoId) {
      this.documentoRespaldoReemplazado = false;
      this.documentoSuscripcionReemplazado = false;
      //   console.log(accion)
      //   console.log(proyectoId)
      this.obtenerInstituciones();
      this.tipoFuenteCooperanteSeleccionada = {};
      this.fuenteBilateralSeleccionada = {};
      this.agenciaSeleccionada = {};
      this.fechaSuscripcion = "";
      this.fechaInicio = "";
      this.fechaFinalizacion = "";
      this.ingresaAPresupuestoSeleccionado = {
        id: 2,
        text: "No"
      };
      this.datosItem = {
        entidadId: null,
        unidadEjecutoraId: null,
        sectoresId: null,
        nombreProyecto: "",
        fechaSuscripcionDonacion: "",
        fechaInicio: "",
        fechaFinalizacion: "",
        unidadesEjecutorasId: 0,
        tiposInstrumentoId: 0,
        tiposCooperacionId: 0,
        modalidadEjecucionId: 0,
        cambiarFuenteCooperante: 1,
        tiposFuenteCooperanteId: 0,
        tiposAprobacionDonacionId: 0,
        numeroDonaciones: "",
        ingresaAPresupuesto: 0,
        codigoFuenteCooperante: "",
        codigoDelProyecto: "",
        codigoInstitucion: "",
        fuentesMultilateralesId: null,
        fuentesBilateralesId: null,
        agenciasId: null,
        agenciaMultilateralId: null,
        cooperantesCNSCId: this.cooperanteId,
        objetivoGeneral: "",
        resultados: "",
      };

      if (accion == 2) {
        this.obtenerDatosItem(proyectoId);
        this.id = proyectoId;
      } else {
        this.$emit("cerrar-dialog-loader");
      }
    },

    onFileChange(tipo, e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          switch (tipo) {
            case "respaldo":
              document.querySelector("#respaldoFile").value = "";
              this.$refs.respaldoFile.reset();
              this.documentoSuscripcionReemplazado = false;
              break;

            case "suscripcion":
              document.querySelector("#suscripcionFile").value = "";
              this.$refs.suscripcionFile.reset();
              this.documentoRespaldoReemplazado = false;
              break;
          }

          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 10) {
          switch (tipo) {
            case "respaldo":
              document.querySelector("#respaldoFile").value = "";
              this.$refs.respaldoFile.reset();
              this.documentoSuscripcionReemplazado = false;
              break;

            case "suscripcion":
              document.querySelector("#suscripcionFile").value = "";
              this.$refs.suscripcionFile.reset();
              this.documentoRespaldoReemplazado = false;
              break;
          }

          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (10Mb)`
          );
          return false;
        }

        switch (tipo) {
          case "respaldo":
            this.archivoRespaldo = e;
            this.documentoRespaldoReemplazado = true;
            break;

          case "suscripcion":
            this.archivoSuscripcion = e;
            this.documentoSuscripcionReemplazado = true;
            break;
        }
      }
    },

    /////////////ESTOS DATOS SE DEBEN OBTENER DEL USUARIO LOGUEADO//////////////////////
    obtenerTipoFuenteUsuario() {
      this.tipoFuenteUsuario.id = 1; //1 es bilateral
      this.obtenerFuentesBilaterales();
      this.fuentesBilateralesId = 3;
      this.obtenerAgenciasPorFuenteBilateral();
      this.agenciasId = 1;
    },

    configIngresaPresupuesto() {
      this.datosItem.codigoInstitucion = "";
      this.datosItem.codigoFuenteCooperante = "";
      this.datosItem.codigoDelProyecto = "";
    },

    //Sectores beneficiados
    obtenerSectoresBeneficiados() {
      this.sectoresBeneficiados = [];
      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Sectores/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.sectoresBeneficiados = res.data;
          }
        })
        .catch(() => {
          this.sectoresBeneficiados = [];
        });
    },

    obtenerTiposCooperacion() {
      this.tiposCooperacion = [];
      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposCooperacion/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.tiposCooperacion = res.data;
          }
        })
        .catch(() => {
          this.tiposCooperacion = [];
        });
    },

    async obtenerDatosItem(idProyecto) {
      this.datosItem = {};
      this.skeletonLoading = true;

      await this.$store
        .dispatch(OBTENER_PROYECTO_CDES, { id: idProyecto })
        .then(res => {
          //console.logres
          if (res.status === 200) {
            this.datosItem = res.data;

            if (this.datosItem.estadoProyectoId != 1) {
              this.seccionesBloqueadas = true;
            } else {
              this.seccionesBloqueadas = false;
            }

            if (this.confirmacion) {
              this.seccionesBloqueadas = true;
            }

            //console.log(this.datosItem.fechaSuscripcionDonacion)
            this.dateSuscripcion = moment(
              this.datosItem.fechaSuscripcionDonacion,
              "DD-MM-YYYY"
            ).format("YYYY-MM-DD");
            this.dateInicio = moment(
              this.datosItem.fechaInicio,
              "DD-MM-YYYY"
            ).format("YYYY-MM-DD");
            this.dateFin = moment(
              this.datosItem.fechaFinalizacion,
              "DD-MM-YYYY"
            ).format("YYYY-MM-DD");

            this.$emit("cerrar-dialog-loader");
            this.$emit("mostrar-registro");
            if (this.datosItem.pathDocumentoSuscripcion) {
              this.documentoSuscripcionRegistrado = true;
            }
            if (this.datosItem.pathRespaldoProyecto) {
              this.documentoRespaldoRegistrado = true;
            }

            //Institucion y unidad ejecutora seleccionada
            if (this.datosItem.entidadId) {
              //this.institucionSeleccionada = this.instituciones.find(item => item.id === this.datosItem.institucionesId);
              this.obtenerUnidadesEjecutorasPorInstitucion(this.datosItem.entidadId).then(() => {
                if (this.datosItem.unidadEjecutoraId && this.datosItem.unidadEjecutoraId != 0 ) {
                  /* this.unidadEjecutoraSeleccionada = this.unidadesEjecutoras.find(item => {
                      if (item.id === this.datosItem.unidadEjecutoraId && item.entidadId === this.datosItem.institucionesId) return true;
                    }
                  ); */
                  //console.log(this.unidadEjecutoraSeleccionada)
                  this.datosItem.unidadEjecutoraId = res.data.unidadEjecutoraId;
                }
              });
            } else {
              this.datosItem.unidadEjecutoraId = null;
            }
          }
          //this.ddTiposInstrumentoLoading = false;
          this.skeletonLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.skeletonLoading = false;
          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    //Obtener las instituciones
    async obtenerInstituciones() {
      //this.datosItem.unidadEjecutoraId =0;
      this.ddInstitucionesLoading = true;
      this.instituciones = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/all/1` })
        .then(res => {
          if (res.status == 200) {
            this.instituciones = res.data;
          }
          this.ddInstitucionesLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
          this.ddInstitucionesLoading = false;
          this.instituciones = [];
        });
    },

    //Obtener las unidades ejecutoras
    async obtenerUnidadesEjecutorasPorInstitucion(institucionesId) {
      this.unidadesEjecutorasLoading = true;
      this.unidadesEjecutoras = [];

      await this.$store
        .dispatch(OBTENER_UNIDADES_EJECUTORAS_ENTIDAD, institucionesId)
        .then(res => {
          if (res.status === 200) {
            this.unidadesEjecutoras = res.data;
          }
          this.unidadesEjecutorasLoading = false;
        })
        .catch(() => {
          this.unidadesEjecutorasLoading = false;
        });
    },

    //Obtener los tipos de fuente cooperante
    obtenerTiposFuenteCooperante() {
      this.tiposFuenteCooperante = [];

      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposFuenteCooperante/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.tiposFuenteCooperante = res.data;
          }
        })
        .catch(() => {
          this.tiposFuenteCooperante = [];
        });
    },

    async obtenerInstrumentos() {
      this.instrumentos = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Instrumentos/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.instrumentos = res.data;
          }
        })
        .catch(() => {
          this.instrumentos = [];
        });
    },

    async obtenerTiposInstrumentos() {
      this.ddTiposInstrumentoLoading = true;
      this.tiposInstrumentos = [];
      // console.log(this.instrumentoSeleccionado.id)
      await this.$store
        .dispatch(OBTENER_SUBITEMS_CATALOGO, {
          endpoint: "TiposInstrumento/all/1",
          id: this.instrumentoSeleccionado.id,
        })
        .then(res => {
          //  console.logres
          if (res.status === 200) {
            this.tiposInstrumentos = res.data;
          }
          this.ddTiposInstrumentoLoading = false;
        })
        .catch(() => {
          this.tiposInstrumentos = [];
          this.ddTiposInstrumentoLoading = false;
        });
    },

    //Obtener las modalidades de ejecución
    async obtenerModalidadesEjecucion() {
      this.modalidadesEjecucion = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "ModalidadEjecucionCNSC/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.modalidadesEjecucion = res.data;
          }
        })
        .catch(() => {
          this.modalidadesEjecucion = [];
        });
    },

    //Obtener las modalidades de ejecución
    async obtenerTiposAprobacionDonacion() {
      this.tiposAprobacionDonacion = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposAprobacionDonacion/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.tiposAprobacionDonacion = res.data;
          }
        })
        .catch(() => {
          this.tiposAprobacionDonacion = [];
        });
    },

    async obtenerFuentesBilaterales() {
      this.fuentesBilaterales = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuentesBilaterales/all/1/0",
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesBilaterales = res.data;
          }
        })
        .catch(() => {
          this.fuentesBilaterales = [];
        });
    },

    async obtenerAgenciasPorFuenteBilateral() {
      this.agencias = [];
      this.ddAgenciasLoading = true;
      await this.$store
        .dispatch(OBTENER_SUBITEMS_CATALOGO, {
          endpoint: "Agencias/all/1",
          id: this.fuenteBilateralSeleccionada.id,
        })
        .then(res => {
          if (res.status === 200) {
            this.agencias = res.data;
          }
          this.ddAgenciasLoading = false;
        })
        .catch(() => {
          this.ddAgenciasLoading = false;
          this.agencias = [];
        });
    },

    async obtenerFuentesMultilateral() {
      this.fuentesMultilateral = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuenteMultiLateral/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesMultilateral = res.data;
          }
        })
        .catch(() => {
          this.fuentesMultilateral = [];
        });
    },

    // Obtener agencias Multilaterales
    async obtenerAgenciasMultilaterales(fuenteMultilateralId) {
      this.agenciasMultilaterales = [];
      this.ddAgenciasMultiLoading = true;

      await this.$store
        .dispatch(OBTENER_AGENCIAS_MULTILATERALES, {
          estadoId: 1,
          fuentesMultilateralesId: fuenteMultilateralId,
        })
        .then(res => {
          if (res.status === 200) {
            this.agenciasMultilaterales = res.data;
          }
          this.ddAgenciasMultiLoading = false;
        })
        .catch(() => {
          //console.log(error)
          this.ddAgenciasMultiLoading = false;
        });
    },

    configurarFuentes() {
      if (this.tipoFuenteCooperanteSeleccionada.id === 1) {
        this.fuenteMultilateralSeleccionada = {};
      } else if (this.tipoFuenteCooperanteSeleccionada.id === 2) {
        this.fuenteBilateralSeleccionada = {};
      } else if (this.tipoFuenteCooperanteSeleccionada.id == 3) {
        this.fuenteBilateralSeleccionada = {};
        this.fuenteMultilateralSeleccionada = {};
      }
    },

    async registrarItem() {
      if (this.accion == 1) {
        this.cargarArchivo(this.archivoRespaldo, "respaldo").then(() => {
          this.cargarArchivo(this.archivoSuscripcion, "suscripcion").then(
            () => {
              if (this.archivosCargado == true) {
                this.registrarProyecto();
              }
            }
          );
        });
      } else {
        if (this.documentoRespaldoReemplazado) {
          this.cargarArchivo(this.archivoRespaldo, "respaldo").then(() => {
            if (this.documentoSuscripcionReemplazado) {
              this.cargarArchivo(this.archivoSuscripcion, "suscripcion").then(() => {
                  if (this.archivosCargado == true) {
                    //console.log("respaldo y suscripcion modificados");
                    this.registrarProyecto();
                  }
                }
              );
            } else {
              if (this.archivosCargado == true) {
                this.registrarProyecto();
                //console.log("solo respaldo modificado");
              }
            }
          });
        } else {
          if (this.documentoSuscripcionReemplazado) {
            this.cargarArchivo(this.archivoSuscripcion, "suscripcion").then(() => {
                if (this.archivosCargado == true) {
                  //console.log("solo suscripcion modificado")
                  this.registrarProyecto();
                }
              }
            );
          } else {
            //console.log("ninguno modificado")
            this.registrarProyecto();
          }
        }
      }
    },

    async cargarArchivo(file, tipo) {
      const files = file;
      let path = "";
      this.btnRegistroLoading = true;
      // this.datosArchivo={};
      //Definir la ruta segun el tipo de archivo

      path = "\\cdesc\\documentos\\";

      await this.$store
        .dispatch(CARGAR_ARCHIVO, { file: files, path: path })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.archivosCargado = true;

            //Agregar el path en función del tipo de archivo que se cargó

            //this.datosArchivo.pathDocumentoRespaldo = res.pathArchivo;
            //this.datosArchivo.path = res.pathArchivo;
            //console.log(res.pathArchivo);
            if (tipo === "respaldo") {
              this.datosItem.pathRespaldoProyecto = res.pathArchivo;
            } else {
              this.datosItem.pathDocumentoSuscripcion = res.pathArchivo;
            }
            //return res.pathArchivo;

            //this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.mensaje);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.mensaje
            );
            this.archivosCargado = false;
            this.btnRegistroLoading = false;
            return null;
          }
          //this.btnRegistroLoading=false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow("warning", "Mensaje", error);
          this.btnRegistroLoading = false;
        });
    },

    //Registrar informacion general
    async registrarProyecto() {
      console.log(this.datosItem.pathRespaldoProyecto)
      console.log(this.datosItem.pathDocumentoSuscripcion)
      this.btnRegistroLoading = true;
      this.datosItem.tiposInstrumentoId = this.tiposInstrumentoSeleccionado.id;
      this.datosItem.cooperantesCNSCId = this.cooperanteId;

      //this.datosItem.tiposCooperacionId = this.tipoCooperacionSeleccionada.id;
      this.datosItem.fechaSuscripcionDonacion = moment(
        this.computedDateFormattedSuscripcion,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      this.datosItem.fechaInicio = moment(
        this.computedDateFormattedInicio,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      this.datosItem.fechaFinalizacion = moment(
        this.computedDateFormattedFinalizacion,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      this.datosItem.modalidadEjecucionCNSCId = this.modalidadEjecucionSeleccionada.id;
      this.datosItem.unidadesEjecutorasId = this.unidadEjecutoraSeleccionada.id;

      this.datosItem.tiposAprobacionDonacionId = this.tipoAprobacionSeleccionada.id;
      this.datosItem.tiposFuenteCooperanteId = this.tipoFuenteUsuario.id;
      //this.datosItem.agenciasId = this.agenciaSeleccionada.id;
      this.datosItem.fuentesBilateralesId = this.fuenteBilateralSeleccionada.id;
      this.datosItem.fuentesMultilateralesId = this.fuenteMultilateralSeleccionada.id;
      this.datosItem.usuarioCreacion = "admin";
      this.datosItem.estadosId = 1;
      this.ingresaAPresupuestoSeleccionado.id === 2
        ? (this.datosItem.ingresaAPresupuesto = 2)
        : (this.datosItem.ingresaAPresupuesto = 1);

      /*   if(this.agenciaSeleccionada.id === 0 || !this.agenciaSeleccionada){
                this.datosItem.agenciasId = null;
            }

 */
      //Verficar si la fuente cooperante es bilateral
      //Verficar si la fuente cooperante es bilateral
      //console.log(this.agenciaSeleccionada)

      this.datosItem.id = 0;

      let dispatch = REGISTRAR_PROYECTO_CDES;
      if (this.accion === 2) {
        dispatch = ACTUALIZAR_DATOS_GENERALES_PROYECTO_CDES;
        this.datosItem.id = this.id;
      }

      await this.$store
        .dispatch(dispatch, {
          datos: this.datosItem
        })
        .then(res => {
          //console.logres
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.$emit("get-items");
            if (this.accion === 1) {
              this.id = res.data.id;
              this.datosItem.id = res.data.id;
              this.$emit("actualizar-id", this.id, this.datosItem.codigo);
              this.datosItem.codigo = res.data.codigo;
            }

            this.accion = 2;

            this.$emit("actualizar-accion", 2);
            this.btnRegistroText = "Actualizar información";
            //  this.switchItemEstado = true;
            this.btnRegistroLoading = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch((error) => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroLoading = false;
        });
    },
    formatDate(date) {
      //console.log(date)
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    //!Descargar el archivo de respaldo
    descargarArchivo(path) {
      /* let newPath = path.replace(/\\/g, '/');
            //console.log(newPath);
            window.open("http://localhost:5236"+ newPath, "_blank"); */
      DownloadFile.download(path);
    }
  },

  created() {
    this.obtenerTipoFuenteUsuario();
    this.resetItems(this.accion, this.id);
    this.obtenerSectoresBeneficiados();
    this.obtenerTiposCooperacion();
    this.obtenerTiposFuenteCooperante();
    this.obtenerFuentesMultilateral();
    this.obtenerModalidadesEjecucion();
    if (this.accion === 2) {
     // this.obtenerDatosItem(this.id);
      this.btnRegistroText = "Actualizar información";
    } else {
      this.btnRegistroText = "Guardar información";
    }
    //this.skeletonLoading=true;
    /*this.obtenerInstituciones();
        
        
        this.obtenerFuentesBilaterales().then(() => {
            this.obtenerAgenciasPorFuenteBilateral();
            this.obtenerTiposCooperacion().then(()=> {
                this.obtenerModalidadesEjecucion().then(()=> {
                    this.obtenerTiposAprobacionDonacion().then(()=> {
                        this.obtenerInstrumentos().then(() =>{
                            this.obtenerDatosItem(this.id);
                        });
                    });
                })
            });
        });
*/
  },
  computed: {
    computedDateFormattedSuscripcion: {
      get() {
        //console.log("asdf")
        return this.formatDate(this.dateSuscripcion);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDate(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }
    },

    computedDateFormattedInicio: {
      get() {
        //console.log("asdf")
        return this.formatDate(this.dateInicio);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDate(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }
    },

    computedDateFormattedFinalizacion: {
      get() {
        //console.log("asdf")
        return this.formatDate(this.dateFin);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDate(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }
    }


  }
};
</script>
