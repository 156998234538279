<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-1">
        <v-subheader class="text-h5 black--text">
          Datos de la institución
        </v-subheader>
      </v-col>
    </v-row>

    <v-row class="pb-1">
      <v-col cols="12" md="12" sm="12">
        <v-alert outlined color="#1488c2" border="left" class="pb-0">
          <p>
            Los campos marcados con un asterisco (<span
              color="red"
              class="red--text"
              >*</span
            >) son obligatorios
          </p>
        </v-alert>
      </v-col>
    </v-row>

    <!-- inicio:: CAD CRS ETC-->
    <v-card class="mt-6" outlined>
      <v-card-text>
        <!-- <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Vinculación estratégica a instrumentos territoriales
                
              </v-subheader>
            </v-col>
          </v-row> -->

        <v-form
          ref="form"
          v-on:submit.prevent="actualizarDatosInstitucion"
          v-model="validForm"
          class="pt-4 pl-2 pr-2"
        >
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
              <v-text-field
                dense
                filled
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                v-model="datosInstitucion.nombreAlcalde"
                label="Nombre del alcalde"
                :rules="[
                  required('Nombre del alcalde'),
                  minLength('Nombre del alcalde', 5),
                  maxLength('Nombre del alcalde', 250)
                ]"
                maxlength="250"
                :disabled="
                  (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                "
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
              <v-text-field
                dense
                filled
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                v-model="datosInstitucion.telefonoInstitucional"
                label="Teléfono institucional"
                :rules="[
                  required('teléfono institucional'),
                  minLength('teléfono institucional', 5),
                  maxLength('teléfono institucional', 25)
                ]"
                maxlength="25"
                :disabled="
                  (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                "
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
              <v-text-field
                dense
                filled
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                v-model="datosInstitucion.correoMunicipalidad"
                label="Correo electrónico"
                :rules="[
                  required('Correo electrónico'),
                  validEmail('Correo electrónico'),
                  maxLength('Correo electrónico', 250)
                ]"
                maxlength="250"
                :disabled="
                  (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                "
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
              <v-text-field
                dense
                filled
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                v-model="datosInstitucion.paginaWeb"
                label="Página web"
                maxlength="250"
                :disabled="
                  (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
              <v-text-field
                dense
                filled
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                v-model="datosInstitucion.nombreContactoEnlace"
                label="Nombre del contacto enlace"
                :rules="[
                  required('Nombre del contacto enlace'),
                  minLength('Nombre del contacto enlace', 5),
                  maxLength('Nombre del contacto enlace', 250)
                ]"
                maxlength="250"
                :disabled="
                  (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                "
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
              <v-text-field
                dense
                filled
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                v-model="datosInstitucion.telefonoContactoEnalce"
                label="Teléfono del contacto enlace"
                :rules="[
                  required('teléfono del contacto enlace'),
                  minLength('teléfono del contacto enlace', 5),
                  maxLength('teléfono del contacto enlace', 25)
                ]"
                maxlength="25"
                :disabled="
                  (tipoUsuario == `ext` && seccionesBloqueadas) || confirmacion
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="!seccionesBloqueadas && !confirmacion">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right mt-2"
                type="submit"
                :elevation="0"
                :disabled="!validForm"
                :loading="btnRegistroLoading"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";

import {
  OBTENER_PROYECTO_CDES,
  ACTUALIZAR_DATOS_INSTITUCION_PROYECTO_CDES
} from "@/core/services/store/proyectoscooperaciondescentralizada/proyectocooperaciondescentralizada.module";

export default {
  name: "SeccionDatosInstitucionCooperacionDesc",
  components: {
    DialogLoader,
    SnackAlert
  },
  props: ["id", "tipoUsuario", "confirmacion", "iaff", "iaffId"], //Tipo de usuario puede ser ext (externo) o int (interno administrador), confirmacion (si se van a bloquear los elementos porque solo se van a visualizar), iaff indica si viene de la vista del iaff, tiene que estar activo todo

  data() {
    return {
      accion: 1,
      btnRegistroLoading: false,
      skeletonLoading: false,
      validForm: false,
      validFormPosicion: false,
      btnRegistroPosicionLoading: false,
      datosProyecto: {},
      datosInstitucion: {
        proyectosCooperacionDescId: this.id,
        nombreAlcalde: "",
        telefonoInstitucional: "",
        correoMunicipalidad: "",
        paginaWeb: "",
        nombreContactoEnlace: "",
        telefonoContactoEnalce: ""
      },
      datosItem: {},
      contactos: [],
      tableContactosLoading: false,
      ...validations
    };
  },
  methods: {
    resetItems(tipo, proyectoId) {
      this.id = proyectoId;
      if (tipo === 2) {
        this.skeletonLoading = true;
      }
      this.resetForm();
      this.obtenerDatosSeccion(this.id);
    },

    resetForm() {
      this.datosInstitucion = {
        proyectosCooperacionDescId: this.id,
        nombreAlcalde: "",
        telefonoInstitucional: "",
        correoMunicipalidad: "",
        paginaWeb: "",
        nombreContactoEnlace: "",
        telefonoContactoEnalce: ""
      };
    },

    //Obtener la información de cobertura
    async obtenerDatosSeccion(proyectoId) {
      this.datosItem = {};
      this.cardDatosLoading = true;

      this.$store
        .dispatch(OBTENER_PROYECTO_CDES, {
          id: proyectoId
        })
        .then(res => {
          if (res.status === 200) {
            this.datosItem = res.data;

            //Bloquear las secciones si es usuario externo y si el proyecto no está en estado registro
            if (this.datosItem.estadoProyectoId != 1) {
              this.seccionesBloqueadas = true;
            } else {
              this.seccionesBloqueadas = false;
            }

            if (this.confirmacion) {
              this.seccionesBloqueadas = true;
            }


            if (this.datosItem.nombreAlcalde)
              this.datosInstitucion.nombreAlcalde = this.datosItem.nombreAlcalde;

            if (this.datosItem.telefonoInstitucional)
              this.datosInstitucion.telefonoInstitucional = this.datosItem.telefonoInstitucional;

            if (this.datosItem.correoMunicipalidad)
              this.datosInstitucion.correoMunicipalidad = this.datosItem.correoMunicipalidad;

            if (this.datosItem.paginaWeb)
              this.datosInstitucion.paginaWeb = this.datosItem.paginaWeb;

            if (this.datosItem.nombreContactoEnlace)
              this.datosInstitucion.nombreContactoEnlace = this.datosItem.nombreContactoEnlace;

            if (this.datosItem.telefonoContactoEnalce)
              this.datosInstitucion.telefonoContactoEnalce = this.datosItem.telefonoContactoEnalce;

            /* if (this.datosItem.cuentaInstitucionesBeneficiarias) {
              this.cuentaInstitucionesBeneficiariasSel = this.institucionesBeneficiariasItems.find(
                item =>
                  item.id === this.datosItem.cuentaInstitucionesBeneficiarias
              );
              if (this.datosItem.cuentaInstitucionesBeneficiarias === 1) {
                this.habilitarRegistroInstituciones = true;
              }
            } else {
              this.cuentaInstitucionesBeneficiariasSel = {
                id: 0,
                text: "Seleccione"
              };
            } */

            //  console.log(this.datosItem.beneficiariosDirectos)

            /* if (this.datosItem.beneficiariosDirectos) {
              this.beneficiarioDirectoSel = this.beneficiariosDirectosItems.find(
                item => item.id === this.datosItem.beneficiariosDirectos
              );
              if (this.datosItem.beneficiariosDirectos === 1) {
                this.habilitarRegistroBeneficiarios = true;
              }
            } else {
              this.beneficiarioDirectoSel = {
                id: 0,
                text: "Seleccione"
              };
            } */
          }
          this.cardDatosLoading = false;
          this.skeletonLoading = false;
          this.condicionDesembolsos.id = this.datosItem.condicionesDesembolsos;
        })
        .catch(() => {
          this.skeletonLoading = false;
          this.cardDatosLoading = false;
        });
    },
    async actualizarDatosInstitucion() {
      this.btnRegistroLoading = true;
      this.datosInstitucion.proyectosCooperacionDescId = this.id;

      await this.$store
        .dispatch(ACTUALIZAR_DATOS_INSTITUCION_PROYECTO_CDES, {
          id: this.id,
          datos: this.datosInstitucion
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          //console.log(res)
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante la actualización. ${error}`
          );
          this.btnRegistroLoading = false;
        });
    }
  },
  created() {
    this.resetItems(1, this.id);
  }
};
</script>
