var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h5 black--text"},[_vm._v(" Datos de la institución ")])],1)],1),_c('v-row',{staticClass:"pb-1"},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-alert',{staticClass:"pb-0",attrs:{"outlined":"","color":"#1488c2","border":"left"}},[_c('p',[_vm._v(" Los campos marcados con un asterisco ("),_c('span',{staticClass:"red--text",attrs:{"color":"red"}},[_vm._v("*")]),_vm._v(") son obligatorios ")])])],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"pt-4 pl-2 pr-2",on:{"submit":function($event){$event.preventDefault();return _vm.actualizarDatosInstitucion.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Nombre del alcalde","rules":[
                _vm.required('Nombre del alcalde'),
                _vm.minLength('Nombre del alcalde', 5),
                _vm.maxLength('Nombre del alcalde', 250)
              ],"maxlength":"250","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosInstitucion.nombreAlcalde),callback:function ($$v) {_vm.$set(_vm.datosInstitucion, "nombreAlcalde", $$v)},expression:"datosInstitucion.nombreAlcalde"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Teléfono institucional","rules":[
                _vm.required('teléfono institucional'),
                _vm.minLength('teléfono institucional', 5),
                _vm.maxLength('teléfono institucional', 25)
              ],"maxlength":"25","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosInstitucion.telefonoInstitucional),callback:function ($$v) {_vm.$set(_vm.datosInstitucion, "telefonoInstitucional", $$v)},expression:"datosInstitucion.telefonoInstitucional"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Correo electrónico","rules":[
                _vm.required('Correo electrónico'),
                _vm.validEmail('Correo electrónico'),
                _vm.maxLength('Correo electrónico', 250)
              ],"maxlength":"250","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosInstitucion.correoMunicipalidad),callback:function ($$v) {_vm.$set(_vm.datosInstitucion, "correoMunicipalidad", $$v)},expression:"datosInstitucion.correoMunicipalidad"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Página web","maxlength":"250","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosInstitucion.paginaWeb),callback:function ($$v) {_vm.$set(_vm.datosInstitucion, "paginaWeb", $$v)},expression:"datosInstitucion.paginaWeb"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Nombre del contacto enlace","rules":[
                _vm.required('Nombre del contacto enlace'),
                _vm.minLength('Nombre del contacto enlace', 5),
                _vm.maxLength('Nombre del contacto enlace', 250)
              ],"maxlength":"250","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosInstitucion.nombreContactoEnlace),callback:function ($$v) {_vm.$set(_vm.datosInstitucion, "nombreContactoEnlace", $$v)},expression:"datosInstitucion.nombreContactoEnlace"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Teléfono del contacto enlace","rules":[
                _vm.required('teléfono del contacto enlace'),
                _vm.minLength('teléfono del contacto enlace', 5),
                _vm.maxLength('teléfono del contacto enlace', 25)
              ],"maxlength":"25","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosInstitucion.telefonoContactoEnalce),callback:function ($$v) {_vm.$set(_vm.datosInstitucion, "telefonoContactoEnalce", $$v)},expression:"datosInstitucion.telefonoContactoEnalce"}})],1)],1),(!_vm.seccionesBloqueadas && !_vm.confirmacion)?_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right mt-2",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" Guardar información ")])],1)],1):_vm._e()],1)],1)],1),_c('SnackAlert',{ref:"snackalert"}),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }