var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article, actions"}})],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-subheader',{staticClass:"text-h5 black--text"},[_vm._v(" Fuente cooperante y financiamiento del proyecto ")])],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-form',{ref:"formTipoBeneficiarios",on:{"submit":function($event){$event.preventDefault();return _vm.registrarDatosFinanciamiento.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0 mt-4",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.procedenciaFondos,"loading":_vm.ddProcedenciaFondosLoading,"dense":"","filled":"","label":"Procedencia de los fondos","item-text":"procedencia","item-value":"id","rules":[_vm.selectRequired('Procedencia de los fondos')],"no-data-text":_vm.procedenciaFondos.length > 0
                    ? 'Seleccione una procedencia de los fondos'
                    : 'No se han encontrado procedencia de fondos',"menu-props":"offset-y","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},on:{"change":_vm.configurarProcedenciaFondos},model:{value:(_vm.datosItem.procedenciaFondos),callback:function ($$v) {_vm.$set(_vm.datosItem, "procedenciaFondos", $$v)},expression:"datosItem.procedenciaFondos"}})],1),(_vm.datosItem.procedenciaFondos === 2)?_c('v-col',{staticClass:"pt-0 pb-0 mt-4",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.paises,"loading":_vm.ddPaisesLoading,"dense":"","filled":"","label":"País","item-text":function (item) { return ("" + (item.nombre)); },"item-value":"id","rules":[
                  _vm.datosItem.procedenciaFondos === 2
                    ? _vm.selectRequired('pais')
                    : true
                ],"no-data-text":_vm.procedenciaFondos.length > 0
                    ? 'Seleccione un país'
                    : 'No se han encontrado países',"disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion,"menu-props":"offset-y"},model:{value:(_vm.datosItem.paisId),callback:function ($$v) {_vm.$set(_vm.datosItem, "paisId", $$v)},expression:"datosItem.paisId"}})],1):_vm._e(),_c('v-col',{staticClass:"pt-0 pb-0 mt-4",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Nombre del Cooperante","rules":[
                  _vm.required('Nombre del Cooperant'),
                  _vm.minLength('Nombre del Cooperant', 10),
                  _vm.maxLength('Nombre del Cooperant', 250)
                ],"maxlength":"250","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosItem.nombreCooperante),callback:function ($$v) {_vm.$set(_vm.datosItem, "nombreCooperante", $$v)},expression:"datosItem.nombreCooperante"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0 mt-4",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.tiposCooperacion,"loading":_vm.ddTiposCooperacionLoading,"dense":"","filled":"","label":"Tipo de cooperación descentralizada","item-text":"tipoCooperacion","item-value":"id","rules":[_vm.selectRequired('Tipo de cooperación descentraliza')],"no-data-text":_vm.procedenciaFondos.length > 0
                    ? 'Seleccione un tipo de cooperación descentraliza'
                    : 'No se han encontrado tipos de cooperación descentraliza',"menu-props":"offset-y","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},on:{"change":function($event){return _vm.configurarInstituciones()}},model:{value:(_vm.datosItem.tipoCooperacionDescId),callback:function ($$v) {_vm.$set(_vm.datosItem, "tipoCooperacionDescId", $$v)},expression:"datosItem.tipoCooperacionDescId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0 mt-4",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.instrumentosCooperacion,"loading":_vm.ddInstrumentosCooperacion,"dense":"","filled":"","label":"Instrumento de cooperación descentralizada","item-text":"instrumento","item-value":"id","rules":[
                  _vm.selectRequired('Instrumento de cooperación descentralizada')
                ],"no-data-text":_vm.procedenciaFondos.length > 0
                    ? 'Seleccione un Instrumento de cooperación descentralizada'
                    : 'No se han encontrado Instrumentos de cooperación descentralizada',"menu-props":"offset-y","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosItem.instrumentosCooperacionDescId),callback:function ($$v) {_vm.$set(_vm.datosItem, "instrumentosCooperacionDescId", $$v)},expression:"datosItem.instrumentosCooperacionDescId"}})],1)],1),(!_vm.institucionesDisbled)?_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{class:!_vm.institucionesDisbled ? "required" : "",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Institución/Organización Ejecutora","rules":[
                  _vm.required('Institución/Organización Ejecutora'),
                  _vm.minLength('Institución/Organización Ejecutora', 2),
                  _vm.maxLength('Institución/Organización Ejecutora', 300)
                ],"maxlength":"300","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.datosItem.nombreEntidadEjecutora),callback:function ($$v) {_vm.$set(_vm.datosItem, "nombreEntidadEjecutora", $$v)},expression:"datosItem.nombreEntidadEjecutora"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-4 pb-0 mb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text "},[_vm._v(" Datos Financieros ")])],1),_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","md":"12"}},[_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-autocomplete',{staticClass:"required",attrs:{"items":_vm.monedas,"loading":_vm.ddMonedasLoading,"dense":"","filled":"","label":"Moneda","item-text":function (item) { return item.nombreMoneda + ' (' + item.codigoMoneda + ')'; },"item-value":"id","return-object":"","rules":[_vm.selectRequired('moneda')],"no-data-text":_vm.monedas != null
                    ? 'Selecciona una moneda'
                    : 'No se han encontrado monedas',"menu-props":"offset-y","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},on:{"change":function($event){_vm.monedaSeleccionada.id === 125
                    ? (_vm.tipoCambio = "1")
                    : (_vm.tipoCambio = "")}},model:{value:(_vm.monedaSeleccionada),callback:function ($$v) {_vm.monedaSeleccionada=$$v},expression:"monedaSeleccionada"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":("Monto moneda original (" + (_vm.monedaSeleccionada.codigoMoneda
                      ? _vm.monedaSeleccionada.codigoMoneda
                      : 'Moneda no seleccionada') + ")"),"rules":[
                  _vm.required('monto moneda original'),
                  _vm.notComma('monto moneda original'),
                  _vm.decimals10('monto moneda original')
                ],"maxlength":"30","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.montoMonedaOriginal),callback:function ($$v) {_vm.montoMonedaOriginal=$$v},expression:"montoMonedaOriginal"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Tipo de cambio","rules":[
                  _vm.required('tipo de cambio'),
                  _vm.decimals10('tipo de cambio')
                ],"maxlength":"30","disabled":(_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas) || _vm.confirmacion},model:{value:(_vm.tipoCambio),callback:function ($$v) {_vm.tipoCambio=$$v},expression:"tipoCambio"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","disabled":true,"autocomplete":"off","color":"blue-grey lighten-2","suffix":"GTQ","label":"Monto total quetzales","maxlength":"30"},model:{value:(_vm.montoTotalQuetzales),callback:function ($$v) {_vm.montoTotalQuetzales=$$v},expression:"montoTotalQuetzales"}})],1)],1),(!_vm.confirmacion)?_c('v-row',[_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","type":"submit","medium":"","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" Actualizar información ")])],1)],1):_vm._e()],1)],1)],1)],1):_vm._e(),_c('SnackAlert',{ref:"snackalert"}),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }