var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article, actions"}})],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h5 black--text"},[_vm._v(" Alcance del proyecto ")])],1)],1),(!_vm.seccionesBloqueadas)?_c('v-row',{staticClass:"pb-3"},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-alert',{attrs:{"outlined":"","color":"#1488c2","border":"left"}},[_c('p',[_vm._v(" Los campos marcados con un asterisco ("),_c('span',{staticClass:"red--text",attrs:{"color":"red"}},[_vm._v("*")]),_vm._v(") son obligatorios ")])])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Descripción del proyecto","rules":[
              _vm.required('descripción del proyecto'),
              _vm.minLength('descripción del proyecto', 10),
              _vm.maxLength('descripción del del proeycto', 2000)
            ],"maxlength":"2000","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosItem.descripcionProyecto),callback:function ($$v) {_vm.$set(_vm.datosItem, "descripcionProyecto", $$v)},expression:"datosItem.descripcionProyecto"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Antecedentes del proyecto","rules":[
              /*required('antecedentes del proyecto'),
                          minLength('antecedentes proyecto', 10),*/
              _vm.maxLength('antecedentes del proeycto', 2000)
            ],"maxlength":"2000","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosItem.antecedentes),callback:function ($$v) {_vm.$set(_vm.datosItem, "antecedentes", $$v)},expression:"datosItem.antecedentes"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Problemática del proyecto","rules":[
              /*required('problematica del proyecto'),
                          minLength('problematica proyecto', 10),*/
              _vm.maxLength('problematica del proeycto', 2000)
            ],"maxlength":"2000","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosItem.problematica),callback:function ($$v) {_vm.$set(_vm.datosItem, "problematica", $$v)},expression:"datosItem.problematica"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Justificación del proyecto","rules":[
              /*required('justificación del proyecto'),
                          minLength('justificación proyecto', 10),*/
              _vm.maxLength('justificación del proeycto', 2000)
            ],"maxlength":"2000","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosItem.justificacion),callback:function ($$v) {_vm.$set(_vm.datosItem, "justificacion", $$v)},expression:"datosItem.justificacion"}})],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Situación del proyecto ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-textarea',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Situación sin proyecto","rules":[
                  /*required('situación sin proyecto'),
                                  minLength('situación sin proyecto', 10),*/
                  _vm.maxLength('situación sin del proeycto', 2000)
                ],"maxlength":"2000","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosItem.situacionSinProyecto),callback:function ($$v) {_vm.$set(_vm.datosItem, "situacionSinProyecto", $$v)},expression:"datosItem.situacionSinProyecto"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-textarea',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Situación con proyecto","rules":[
                  /*required('situación con proyecto'),
                                  minLength('situación con proyecto', 10),*/
                  _vm.maxLength('situación con del proeycto', 2000)
                ],"maxlength":"2000","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosItem.situacionConProyecto),callback:function ($$v) {_vm.$set(_vm.datosItem, "situacionConProyecto", $$v)},expression:"datosItem.situacionConProyecto"}})],1)],1)],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Objetivos del proyecto ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Objetivo general del proyecto","rules":[
                  _vm.required('objetivo general del proyecto'),
                  _vm.minLength('objetivo general del proyecto', 10),
                  _vm.maxLength('objetivo general del del proeycto', 2000)
                ],"maxlength":"2000","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosItem.objetivoGeneral),callback:function ($$v) {_vm.$set(_vm.datosItem, "objetivoGeneral", $$v)},expression:"datosItem.objetivoGeneral"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Objetivo especifico 1 del proyecto","rules":[
                  /*required('objetivo especifico 1 del proyecto'),
                                  minLength('objetivo especifico 1 del proyecto', 10),*/
                  _vm.maxLength('objetivo especifico 1 del del proeycto', 2000)
                ],"maxlength":"2000","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosItem.objetivoEspecifico1),callback:function ($$v) {_vm.$set(_vm.datosItem, "objetivoEspecifico1", $$v)},expression:"datosItem.objetivoEspecifico1"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-textarea',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Objetivo especifico 2 del proyecto","rules":[
                  /*required('objetivo especifico 1 del proyecto'),
                                  minLength('objetivo especifico 1 del proyecto', 10),*/
                  _vm.maxLength('objetivo especifico 1 del del proeycto', 2000) ],"maxlength":"2000","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosItem.objetivoEspecifico2),callback:function ($$v) {_vm.$set(_vm.datosItem, "objetivoEspecifico2", $$v)},expression:"datosItem.objetivoEspecifico2"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-textarea',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Objetivo especifico 3 del proyecto","rules":[
                  /*required('objetivo especifico 1 del proyecto'),
                                  minLength('objetivo especifico 1 del proyecto', 10),*/
                  _vm.maxLength('objetivo especifico 1 del del proeycto', 2000)
                ],"maxlength":"2000","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosItem.objetivoEspecifico3),callback:function ($$v) {_vm.$set(_vm.datosItem, "objetivoEspecifico3", $$v)},expression:"datosItem.objetivoEspecifico3"}})],1)],1)],1)],1),(!_vm.seccionesBloqueadas || _vm.tipoUsuario == "int")?_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue darken-2","type":"submit","elevation":0,"disabled":!_vm.validForm || (_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas),"loading":_vm.btnRegistroLoading}},[_vm._v(" Guardar información ")])],1)],1):_vm._e()],1),_c('v-card',{staticClass:"mt-10",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-form',{ref:"form2",on:{"submit":function($event){$event.preventDefault();return _vm.registrarIndicador.apply(null, arguments)}},model:{value:(_vm.validFormIndicador),callback:function ($$v) {_vm.validFormIndicador=$$v},expression:"validFormIndicador"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-4 pb-0 mb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Indicadores del proyecto ")])],1),(!_vm.seccionesBloqueadas || _vm.tipoUsuario == "int")?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Indicadores del proyecto","rules":[
                  _vm.required('indicadores del proyecto'),
                  _vm.minLength('indicadores del proyecto', 10),
                  _vm.maxLength('indicadores del proyecto', 2000)
                ],"maxlength":"2000","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosIndicador.detalleIndicador),callback:function ($$v) {_vm.$set(_vm.datosIndicador, "detalleIndicador", $$v)},expression:"datosIndicador.detalleIndicador"}})],1):_vm._e(),(!_vm.seccionesBloqueadas || _vm.tipoUsuario == "int")?_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"mb-2 float-right align-bottom",attrs:{"color":"primary","type":"submit","elevation":0,"disabled":!_vm.validFormIndicador ||
                    (_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas),"loading":_vm.btnRegistroIndicadorLoading}},[_vm._v(" Agregar indicador ")])],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersIndicadores,"items":_vm.indicadores,"loading":_vm.tableLoadingIndicadores,"hide-default-footer":"","no-data-text":"No se encontraron registros","loading-text":"Cargando...","header-props":{
                  sortByText: 'Ordenar por'
                },"footer-props":{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}',
                }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.detalleIndicador))]),_c('td',[(item.estadosId == 1)?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","disabled":_vm.btnEliminarIndicadorDisabled ||
                            (_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas),"color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.eliminarIndicador(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar ")],1):_vm._e()],1)])]}}],null,false,2027483116)})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-form',{ref:"form2",on:{"submit":function($event){$event.preventDefault();return _vm.registrarRiesgo.apply(null, arguments)}},model:{value:(_vm.validFormRiesgos),callback:function ($$v) {_vm.validFormRiesgos=$$v},expression:"validFormRiesgos"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-4 pb-0 mb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Riesgos del proyecto ")])],1),(!_vm.seccionesBloqueadas || _vm.tipoUsuario == "int")?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Describa los riegos del proyecto","rules":[
                  _vm.required('riesgos del proyecto'),
                  _vm.minLength('riesgos del proyecto', 10),
                  _vm.maxLength('riesgos del del proeycto', 2000)
                ],"maxlength":"2000","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosRiesgo.detalleRiesgo),callback:function ($$v) {_vm.$set(_vm.datosRiesgo, "detalleRiesgo", $$v)},expression:"datosRiesgo.detalleRiesgo"}})],1):_vm._e(),(!_vm.seccionesBloqueadas || _vm.tipoUsuario == "int")?_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"mb-2 float-right align-bottom",attrs:{"color":"primary","type":"submit","elevation":0,"disabled":!_vm.validFormRiesgos ||
                    (_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas),"loading":_vm.btnRegistroRiesgoLoading}},[_vm._v(" Agregar riesgo ")])],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersRiesgos,"items":_vm.riesgos,"loading":_vm.tableLoadingRiesgos,"hide-default-footer":"","no-data-text":"No se encontraron registros","loading-text":"Cargando...","header-props":{
                  sortByText: 'Ordenar por'
                },"footer-props":{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}',
                }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.detalleRiesgo))]),_c('td',[(item.estadosId == 1)?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","disabled":_vm.btnEliminarRiesgoDisabled ||
                            (_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas),"color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.eliminarRiesgo(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar ")],1):_vm._e()],1)])]}}],null,false,2957660472)})],1)],1)],1)],1)],1),_c('SnackAlert',{ref:"snackalert"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }