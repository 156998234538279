var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article, actions"}})],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-3",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h5 black--text"},[_vm._v(" Contactos del proyecto ")])],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[(_vm.tipo===2)?_c('v-form',{ref:"formProyeccionDesembolsos",on:{"submit":function($event){$event.preventDefault();return _vm.registrarContactos.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","items":_vm.tiposContacto,"color":"blue-grey lighten-2","label":"Seleccione el tipo de contacto","item-text":"tipo","item-value":"id","rules":[_vm.selectRequired('tipo de contacto')],"menu-props":"auto","return-object":""},model:{value:(_vm.tipoContactoSeleccionado),callback:function ($$v) {_vm.tipoContactoSeleccionado=$$v},expression:"tipoContactoSeleccionado"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":_vm.tipoContactoSeleccionado.id === 8 ? 'Nombre de la máxima autoridad' : _vm.tipoContactoSeleccionado.id === 7 ? 'Nombre del gerente' : 'Nombre',"rules":[
                                    _vm.required('nombre del contacto'),
                                    _vm.minLength('nombre del contacto', 5),
                                    _vm.maxLength('nombre del contacto', 100)
                                ],"maxlength":"100"},model:{value:(_vm.datosContacto.nombre),callback:function ($$v) {_vm.$set(_vm.datosContacto, "nombre", $$v)},expression:"datosContacto.nombre"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Correo electrónico","rules":[
                                    _vm.required('correo electrónico'),
                                    _vm.minLength('correo electrónico', 5),
                                    _vm.maxLength('correo electrónico', 150),
                                    _vm.validEmail('correo electrónico')
                                ],"maxlength":"150"},model:{value:(_vm.datosContacto.correoElectronicoPersonal),callback:function ($$v) {_vm.$set(_vm.datosContacto, "correoElectronicoPersonal", $$v)},expression:"datosContacto.correoElectronicoPersonal"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Correo electrónico institucional","rules":[
                                    _vm.required('correo electrónico'),
                                    _vm.minLength('correo electrónico', 5),
                                    _vm.maxLength('correo electrónico', 150),
                                    _vm.validEmail('correo electrónico')
                                ],"maxlength":"150"},model:{value:(_vm.datosContacto.correoElectronicoInstitucional),callback:function ($$v) {_vm.$set(_vm.datosContacto, "correoElectronicoInstitucional", $$v)},expression:"datosContacto.correoElectronicoInstitucional"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Número de teléfono personal","rules":[
                                    _vm.minLength('teléfono personal', 8),
                                    _vm.maxLength('teléfono personal', 12) ],"maxlength":"12"},model:{value:(_vm.datosContacto.telefonoPersonal),callback:function ($$v) {_vm.$set(_vm.datosContacto, "telefonoPersonal", $$v)},expression:"datosContacto.telefonoPersonal"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Número de teléfono institucional","rules":[
                                    _vm.minLength('teléfono institucional', 8),
                                    _vm.maxLength('teléfono institucional', 12) ],"maxlength":"12"},model:{value:(_vm.datosContacto.telefonoInstitucional),callback:function ($$v) {_vm.$set(_vm.datosContacto, "telefonoInstitucional", $$v)},expression:"datosContacto.telefonoInstitucional"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"mb-2 float-right",attrs:{"color":"primary","type":"submit","large":"","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroContactoLoading}},[_vm._v(" Registrar contacto ")])],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Contactos registrados ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersContactos,"items":_vm.contactos,"loading":_vm.tableContactosLoading,"hide-default-footer":"","footer-props":{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.tipo))]),_c('td',[_vm._v(_vm._s(item.nombre))]),_c('td',[_vm._v(" "+_vm._s(item.correoElectronicoPersonal)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.correoElectronicoInstitucional)+" ")]),_c('td',[_c('v-chip',{staticClass:"ma-2 font-weight-medium",attrs:{"label":"","color":item.estadosId === 1
                                                    ? 'blue lighten-4'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-4'
                                                    : item.estadosId === 6
                                                    ? 'cyan lighten-4'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-4'
                                                    : 'pink lighten-4',"text-color":item.estadosId === 1
                                                    ? 'blue lighten-1'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-1'
                                                    : item.estadosId === 6
                                                    ? 'cyan darken-1'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-1'
                                                    : 'pink darken-1',"small":""}},[_vm._v(" "+_vm._s(item.estado)+" ")])],1),_c('td',[(item.estadosId==1)?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","disabled":_vm.btnEliminarDisabled,"color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.eliminarContacto(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar ")],1):_vm._e()],1)])]}}],null,false,1141255991)})],1)],1)],1)],1),_c('SnackAlert',{ref:"snackalert"}),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }