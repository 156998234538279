<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="8">
            Cooperación descentralizada
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-btn
              color="light-blue-502"
              dark
              class="white--text mb-2 float-right"
              @click="modalNuevo"
            >
              Registrar proyecto
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="proyectos"
          :search="filtro"
          :loading="tableLoading"
          no-data-text="No se encontraron registros"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-text-field
                    filled
                    autocomplete="off"
                    dense
                    v-model="filtro"
                    label="Código / Nombre del proyecto"
                    maxlength="100"
                  >
                    <v-icon slot="append" color="black">
                      mdi-magnify
                    </v-icon>
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-select
                    v-model="estadoProyecto"
                    :items="estadosProyecto"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.codigo }}</td>
              <td>{{ item.nombreProyecto }}</td>
              <td>{{ item.fechaSuscripcionDonacion }}</td>
              <td>
                {{ item.nombreInstitucion }},<br />
                <span>{{ item.nombreUnidadEjecutora }}</span>
              </td>
              <td>{{ item.sector }}</td>
              <td>
                <v-chip
                  label
                  class="ma-2"
                  :color="
                    item.estadoProyectoId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadoProyectoId === 2
                      ? 'blue lighten-4'
                      : item.estadoProyectoId === 6
                      ? 'cyan lighten-4'
                      : item.estadoProyectoId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadoProyectoId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadoProyectoId === 2
                      ? 'blue lighten-1'
                      : item.estadoProyectoId === 6
                      ? 'cyan darken-1'
                      : item.estadoProyectoId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estadoProyecto }}
                </v-chip>
              </td>
              <td>
                <v-btn v-if="item.estadoProyectoId === 1"
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="mostrarModalActualizar(item.id, item.codigo)"
                >
                  <v-icon left>mdi-pencil</v-icon> Actualizar
                </v-btn>

                <v-btn
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize d-none"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-eye</v-icon>
                </v-btn>

                <!-- <v-btn
                  v-if="item.estadoProyectoId === 1 || item.estadoProyectoId === 9"
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="prepararEnvio(item.id, item.nombreProyecto)"
                >
                  <v-icon left>mdi-send</v-icon> Enviar
                </v-btn> -->

                <!-- <v-btn
                  v-if="item.estadoProyectoId === 2"
                  class="ma-2 btn-bg-light teal--text lighten-1--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="teal lighten-4"
                  @click="mostrarDialogAprobacion(item.id, item.nombreProyecto)"
                >
                  <v-icon left>mdi-check-all</v-icon> Aprobar
                </v-btn>

                <v-btn
                  v-if="item.estadoProyectoId === 2"
                  class="ma-2 btn-bg-light red--text darken-1--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="red lighten-4"
                  @click="mostrarDialogRechazo(item.id, item.nombreProyecto)"
                >
                  <v-icon left>mdi-close</v-icon> Rechazar
                </v-btn> -->

                <!-- <v-menu
                  v-model="menuAvanceProyecto[item.id]"
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                      color="blue-grey lighten-5"
                      dark
                      small
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      @click="mostrarAvanceProyecto(item.id)"
                    >
                      <v-icon left>mdi-percent</v-icon> Detalle del avance
                    </v-btn>
                  </template>

                  <v-card class="elevation-0">
                    <div v-if="skeletonAvance[item.id]">
                      <v-row>
                          <v-col cols="12" md="12">
                                <v-skeleton-loader
                                v-bind="attrs"
                                min-height="200px"
                                type="article, article"
                                >
                                </v-skeleton-loader>
                        </v-col>
                      </v-row>
                    </div>

                    <div v-if="!skeletonAvance[item.id]">
                      
                        

                        <v-list>
                          <v-list-item>
                        

                          <v-list-item-content>
                            <v-list-item-title>Avance del Registro | {{item.codigo}}</v-list-item-title>
                            
                          </v-list-item-content>
                        </v-list-item>

                          <v-list-item style="height: 25px; min-height: 25px;">
                            <v-list-item-content>
                              <v-list-item-subtitle class="text-subtitle-1">
                                <v-icon small :color="informacionGeneralRegistrada ? 'blue darken-2' : 'orange darken-2'">
                                  {{informacionGeneralRegistrada ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                                </v-icon> Información general del proyecto
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item style="height: 25px; min-height: 25px;">
                            <v-list-item-content>
                              <v-list-item-subtitle class="text-subtitle-1">
                                <v-icon small :color="alcanceRegistrado ? 'blue darken-2' : 'orange darken-2'">
                                  {{alcanceRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                                </v-icon> Alcance del proyecto
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item style="height: 25px; min-height: 25px;">
                            <v-list-item-content>
                              <v-list-item-subtitle class="text-subtitle-1">
                                <v-icon small :color="fechasPlazosRegistradas ? 'blue darken-2' : 'orange darken-2'">
                                  {{fechasPlazosRegistradas ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                                </v-icon> Plazos del proyecto
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          

                          <v-list-item style="height: 25px; min-height: 25px;">
                            <v-list-item-content>
                              <v-list-item-subtitle class="text-subtitle-1">
                                <v-icon small :color="coberturaRegistrada ? 'blue darken-2' : 'orange darken-2'">
                                  {{coberturaRegistrada ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                                </v-icon> Cobertura y vinculación
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          
                          <v-list-item style="height: 25px; min-height: 25px;">
                            <v-list-item-content>
                              <v-list-item-subtitle class="text-subtitle-1">
                                <v-icon small :color="documentoJustificacionRegistrado ? 'blue darken-2' : 'orange darken-2'">
                                  {{documentoJustificacionRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                                </v-icon> Documento de jsutificación
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                  
                          <v-list-item style="height: 25px; min-height: 25px;">
                            <v-list-item-content>
                              <v-list-item-subtitle class="text-subtitle-1">
                                <v-icon small :color="documentoOpiniontecnicaRegistrado ? 'blue darken-2' : 'orange darken-2'">
                                  {{documentoOpiniontecnicaRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                                </v-icon> Documento de opinión técnica
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item style="height: 25px; min-height: 25px;">
                            <v-list-item-content>
                              <v-list-item-subtitle class="text-subtitle-1">
                                <v-icon small :color="documentoProyectoRegistrado ? 'blue darken-2' : 'orange darken-2'">
                                  {{documentoProyectoRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                                </v-icon> Documento del proyecto
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item style="height: 25px; min-height: 25px;">
                            <v-list-item-content>
                              <v-list-item-subtitle class="text-subtitle-1">
                                <v-icon small :color="documentoConvenioRegistrado ? 'blue darken-2' : 'orange darken-2'">
                                  {{documentoConvenioRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                                </v-icon> Documento de convenio
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item style="height: 25px; min-height: 25px;">
                            <v-list-item-content>
                              <v-list-item-subtitle class="text-subtitle-1">
                                <v-icon small :color="contactosRegistrados ? 'blue darken-2' : 'orange darken-2'">
                                  {{contactosRegistrados ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                                </v-icon> Contactos
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                        

                        </v-list>
                    </div>
                  </v-card>
                </v-menu> -->

                <!--  <v-btn v-if="item.estadoProyectoId === 1"
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="mostrarDialogConfirmacion(item.id, item.codigo)"
                >
                  <v-icon left>mdi-check-all</v-icon> Confirmar información
                </v-btn>
 -->

                <!-- <v-menu  v-if="item.estadoProyectoId === 2"
                  bottom
                  offset-y
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                      color="blue-grey lighten-5"
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      dense
                      small
                    >
                      <v-icon left>mdi-eraser-variant</v-icon>Enmiendas
                    </v-btn>
                  </template>
                  <v-list>
                     <v-list-item v-if="item.estadoProyectoId === 2" :to="{name: 'admin-enmiendas-componentes', params: { codigoProyecto: item.codigo, proyectoId: $CryptoJS.AES.encrypt(item.id.toString(),'KEYADMINCNS2022').toString()}}">
                       <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title>Enmiendas de componentes</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="item.estadoProyectoId === 2" :to="{name: 'admin-enmiendas-fechas', params: { codigoProyecto: item.codigo, proyectoId: $CryptoJS.AES.encrypt(item.id.toString(),'KEYADMINCNS2022').toString()}}">
                       <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title>Enmiendas de fechas</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="item.estadoProyectoId === 2" :to="{name: 'admin-enmiendas-montos', params: { codigoProyecto: item.codigo, proyectoId: $CryptoJS.AES.encrypt(item.id.toString(),'KEYADMINCNS2022').toString()}}">
                       <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title>Enmiendas de montos</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-menu  v-if="item.estadoProyectoId === 1 || item.estadoProyectoId === 2"
                  bottom
                  offset-y
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                      color="blue-grey lighten-5"
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      dense
                      small
                    >
                      <v-icon left>mdi-format-list-bulleted</v-icon>Mas acciones
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-if="item.estadoProyectoId === 1 || item.estadoProyectoId === 2" :to="{name: 'admin-poa', params: { codigoProyecto: item.codigo, proyectoId: $CryptoJS.AES.encrypt(item.id.toString(),'KEYADMINCNS2022').toString()}}">
                       <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title>Registro de Programación Anual de proyecto</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="item.estadoProyectoId === 2" :to="{name: 'admin-iaff', params: { codigoProyecto: item.codigo, proyectoId: $CryptoJS.AES.encrypt(item.id.toString(),'KEYADMINCNS2022').toString()}}">
                       <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title>Registro de Informe de Avance Físico y Financiero</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>         
                  </v-list>          
                </v-menu> -->
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template> -->
    </v-card>

    <v-dialog
      v-model="dialogRegistro"
      persistent
      max-width="1280px"
      transition="scroll-y-transition"
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ modalTitle }} {{ codigoProyecto }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogRegistro = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-tabs
            v-model="tab"
            background-color="light-blue-502"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              Información General
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

            <v-tab href="#tab-2" :disabled="accion === 1" class="d-none">
              Alcance del proyecto
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-tab>

            <v-tab href="#tab-3" :disabled="accion === 1">
              Datos Financieros
              <v-icon>mdi-cash-100</v-icon>
            </v-tab>
            <v-tab href="#tab-4" :disabled="accion === 1" class="d-none">
              Plazos del proyecto
              <v-icon>mdi-av-timer</v-icon>
            </v-tab>

            <v-tab href="#tab-5" :disabled="accion === 1">
              Vinculación estratégica
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-6" :disabled="accion == 1">
              Beneficiarios
              <v-icon>mdi-account-multiple-plus-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-7" :disabled="true" class="d-none">
              Actor implementador
              <v-icon>mdi-account-details</v-icon>
            </v-tab>

            <v-tab href="#tab-8" :disabled="accion === 1" class="d-none">
              Contactos
              <v-icon>mdi-account-box-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-9" :disabled="accion === 1">
              Datos de Institución
              <v-icon>mdi-office-building</v-icon>
            </v-tab>

            <v-tab
              href="#tab-10"
              :disabled="accion === 1"
              @click="actualizarAvanceProyecto(idProyecto)"
            >
              Confirmar información
              <v-icon left>mdi-check-all</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text>
                  <SeccionDatosGeneralesProyectoDescentralizada
                    ref="SeccionDatosGeneralesProyectoDescentralizada"
                    :tipo="tipo"
                    :id="idProyecto"
                    :cooperanteId="cooperanteId"
                    :accion="accion"
                    @get-items="obtenerProyectos"
                    @actualizar-accion="actualizarAccion"
                    @actualizar-id="actualizarId"
                    @mostrar-registro="mostrarDialogRegistro"
                    @cerrar-dialog-loader="cerrarDialogLoader"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text>
                  <SeccionAlcanceProyecto
                    ref="seccionAlcance"
                    :id="idProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-3'">
              <v-card flat>
                <v-card-text>
                  <SeccionFuenteCooperanteProyectosDescentralizada
                    ref="SeccionFuenteCooperanteProyectosDescentralizada"
                    :tipo="tipo"
                    :id="idProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-4'">
              <v-card flat>
                <v-card-text>
                  <SeccionPlazosProyecto ref="seccionPlazos" :id="idProyecto" />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-5'">
              <v-card flat>
                <v-card-text>
                  <SeccionVinculacionEstrategicaProyectoCooperacion
                    ref="seccionVinculacionEstrategicaProyectoCooperacion"
                    :id="idProyecto"
                    :tipo="tipo"
                    :tipoAccion="accion"
                    :tipoUsuario="`int`"
                    :confirmacion="false"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-6'">
              <v-card flat>
                <v-card-text>
                  <SeccionBeneficiariosProyectoCooperacionDesc
                    ref="SeccionBeneficiariosProyectoCooperacionDesc"
                    :id="idProyecto"
                    :tipo="tipo"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-7'">
              <v-card flat>
                <v-card-text>
                  <SeccionActorImplementadorProyectoCooperante
                    ref="seccionActorImplementadorProyectoCooperante"
                    :id="idProyecto"
                    :tipo="tipo"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-8'">
              <v-card flat>
                <v-card-text>
                  <SeccionContactosProyectoCooperante
                    ref="seccionContactos"
                    :id="idProyecto"
                    :tipo="tipo"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-9'">
              <v-card flat>
                <v-card-text>
                  <SeccionDatosInstitucionCooperacionDesc
                    ref="SeccionDatosInstitucionCooperacionDesc"
                    :id="idProyecto"
                    :tipo="tipo"
                    :tipoAccion="accion"
                    :tipoUsuario="`int`"
                    :confirmacion="false"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-10'">
              <v-card flat>
                <v-card-text>
                  <SeccionConfirmarProyectoCooperacionDesc
                    ref="SeccionConfirmarProyectoCooperacionDesc"
                    :id="idProyecto"
                    :tipo="tipo"
                    :tipoAccion="accion"
                    :tipoUsuario="`int`"
                    :confirmacion="false"
                    @mostrar-dialog-confirmar="mostrarDialogConfirmacion"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Modal para confirmar el proyecto-->
    <v-dialog
      v-model="dialogConfirmacion"
      persistent
      max-width="1200px"
      transition="scroll-y-transition"
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ modalTitleConfirmar }} {{ codigoProyecto }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogConfirmacion = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-tabs
            v-model="tabConfirmacion"
            background-color="light-blue-502"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              Información General
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

            <v-tab href="#tab-2" :disabled="accion === 1" class="d-none">
              Alcance del proyecto
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-tab>

            <v-tab href="#tab-3" :disabled="accion === 1">
              Datos Financieros
              <v-icon>mdi-cash-100</v-icon>
            </v-tab>
            <v-tab href="#tab-4" :disabled="accion === 1" class="d-none">
              Plazos del proyecto
              <v-icon>mdi-av-timer</v-icon>
            </v-tab>

            <v-tab href="#tab-5" :disabled="accion === 1">
              Vinculación estratégica
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-6" :disabled="accion == 1">
              Beneficiarios
              <v-icon>mdi-account-multiple-plus-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-7" :disabled="true" class="d-none">
              Actor implementador
              <v-icon>mdi-account-details</v-icon>
            </v-tab>

            <v-tab href="#tab-8" :disabled="accion === 1" class="d-none">
              Contactos
              <v-icon>mdi-account-box-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-9" :disabled="accion === 1">
              Datos de Institución
              <v-icon>mdi-office-building</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabConfirmacion" touchless>
            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text>
                  <SeccionDatosGeneralesProyectoDescentralizada
                    ref="SeccionDatosGeneralesProyectoDescentralizadaConfirmacion"
                    :tipo="tipo"
                    :id="idProyecto"
                    :cooperanteId="cooperanteId"
                    :accion="accion"
                    @get-items="obtenerProyectos"
                    @actualizar-accion="actualizarAccion"
                    @actualizar-id="actualizarId"
                    @mostrar-registro="mostrarDialogRegistro"
                    @cerrar-dialog-loader="cerrarDialogLoader"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text>
                  <SeccionAlcanceProyecto
                    ref="seccionAlcanceConfirmacion"
                    :id="idProyecto"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-3'">
              <v-card flat>
                <v-card-text>
                  <SeccionFuenteCooperanteProyectosDescentralizada
                    ref="SeccionFuenteCooperanteProyectosDescentralizadaConfirmacion"
                    :tipo="tipo"
                    :id="idProyecto"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-4'">
              <v-card flat>
                <v-card-text>
                  <SeccionPlazosProyecto
                    ref="seccionPlazosConfirmacion"
                    :id="idProyecto"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-5'">
              <v-card flat>
                <v-card-text>
                  <SeccionVinculacionEstrategicaProyectoCooperacion
                    ref="seccionVinculacionEstrategicaProyectoCooperacionConfirmacion"
                    :id="idProyecto"
                    :tipo="tipo"
                    :tipoAccion="accion"
                    :tipoUsuario="`int`"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-6'">
              <v-card flat>
                <v-card-text>
                  <SeccionBeneficiariosProyectoCooperacionDesc
                    ref="SeccionBeneficiariosProyectoCooperacionDescConfirmacion"
                    :id="idProyecto"
                    :tipo="tipo"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-7'">
              <v-card flat>
                <v-card-text>
                  <SeccionActorImplementadorProyectoCooperante
                    ref="seccionActorImplementadorProyectoCooperanteConfirmacion"
                    :id="idProyecto"
                    :tipo="tipo"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-8'">
              <v-card flat>
                <v-card-text>
                  <SeccionContactosProyectoCooperante
                    ref="seccionContactosConfirmacion"
                    :id="idProyecto"
                    :tipo="tipo"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-9'">
              <v-card flat>
                <v-card-text>
                  <SeccionDatosInstitucionCooperacionDesc
                    ref="SeccionDatosInstitucionCooperacionDescConfirmacion"
                    :id="idProyecto"
                    :tipo="tipo"
                    :tipoAccion="accion"
                    :tipoUsuario="`int`"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

          <v-card>
            <v-card-text>
              <v-row class="mt-2">
                <v-col cols="12">
                  <p class="text-subtitle-1 mb-1 pb-0" v-if="datosPendientes > 0">
                    Existe información pendiente de registrar para el proyecto
                    {{ codigoProyectoCNS }}.
                  </p>
                  <p class="text-subtitle-1 mb-1 pb-0" v-if="datosPendientes > 0">
                    Por favor, complete la información e inténtelo nuevamente
                  </p>
                  <p class="text-subtitle-1" v-if="datosPendientes > 0">
                    Información pendiente de registrar:
                  </p>

                  <v-list>
                    <v-list-item style="height: 25px; min-height: 25px;">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-subtitle-1">
                          <v-icon
                            small
                            :color="
                              informacionGeneralRegistrada
                                ? 'blue darken-2'
                                : 'orange darken-2'
                            "
                          >
                            {{
                              informacionGeneralRegistrada
                                ? "mdi-check-all"
                                : "mdi-alert-circle-outline"
                            }}
                          </v-icon>
                          Información general del proyecto
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item style="height: 25px; min-height: 25px;">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-subtitle-1">
                          <v-icon
                            small
                            :color="
                              datosFinancierosRegistrados
                                ? 'blue darken-2'
                                : 'orange darken-2'
                            "
                          >
                            {{
                              datosFinancierosRegistrados
                                ? "mdi-check-all"
                                : "mdi-alert-circle-outline"
                            }}
                          </v-icon>
                          Fuente cooperante y financiamiento del proyecto
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item style="height: 25px; min-height: 25px;">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-subtitle-1">
                          <v-icon
                            small
                            :color="
                              vinculacionEstrategicaRegistrada
                                ? 'blue darken-2'
                                : 'orange darken-2'
                            "
                          >
                            {{
                              vinculacionEstrategicaRegistrada
                                ? "mdi-check-all"
                                : "mdi-alert-circle-outline"
                            }}
                          </v-icon>
                          Vinculación estratégica
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item style="height: 25px; min-height: 25px;">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-subtitle-1">
                          <v-icon
                            small
                            :color="
                              beneficiariosRegistrados
                                ? 'blue darken-2'
                                : 'orange darken-2'
                            "
                          >
                            {{
                              beneficiariosRegistrados
                                ? "mdi-check-all"
                                : "mdi-alert-circle-outline"
                            }}
                          </v-icon>
                          Beneficiarios
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item style="height: 25px; min-height: 25px;">
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-subtitle-1">
                          <v-icon
                            small
                            :color="
                              datosInstitucionRegistrados
                                ? 'blue darken-2'
                                : 'orange darken-2'
                            "
                          >
                            {{
                              datosInstitucionRegistrados
                                ? "mdi-check-all"
                                : "mdi-alert-circle-outline"
                            }}
                          </v-icon>
                          Datos de la institución
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      class="mt-4"
                      style="height: 25px; min-height: 25px;"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-subtitle-1">
                          <v-chip
                            class="ma-2"
                            label
                            :color="
                              porcentajeAvance == 100
                                ? `light-blue lighten-2`
                                : porcentajeAvance >= 0 && porcentajeAvance <= 10
                                ? `red lighten-2`
                                : porcentajeAvance < 10 && porcentajeAvance <= 30
                                ? `deep-orange lighten-2`
                                : porcentajeAvance < 30 && porcentajeAvance <= 50
                                ? `orange lighten-2`
                                : porcentajeAvance < 50 && porcentajeAvance <= 70
                                ? `yellow lighten-2`
                                : porcentajeAvance < 70 && porcentajeAvance <= 90
                                ? `lime lighten-2`
                                : porcentajeAvance < 90 && porcentajeAvance <= 99
                                ? `green lighten-2`
                                : `blue`
                            "
                          >
                            {{ porcentajeAvance }}% información registrada
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <!-- {{datosProyecto}}-->

                  <p class="text-subtitle-1" v-if="datosPendientes <= 0">
                    ¿Está seguro de confirmar la información del proyecto
                    {{ codigoProyectoCNS }}?
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="blue-grey darken-2"
                text
                @click="dialogConfirmacion = false"
                :disabled="btnEnvioLoading"
              >
                Cancelar
              </v-btn>

              <v-btn
                color="blue-grey darken-2"
                text
                @click="confirmarProyecto"
                :loading="btnEnvioLoading"
                :disabled="porcentajeAvance < 100"
              >
                Sí, confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--begin:: dialog confirmar información v-model="dialogConfirmacion"-->
    <v-dialog width="600" transition="scroll-y-transition" persistent>
      <v-card>
        <v-card-title class="text-h5">
          Confirmación de la información

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEnvioLoading"
            @click="dialogConfirmacion = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <!--<v-divider></v-divider>-->

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogConfirmacion = false"
            :disabled="btnEnvioLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="confirmarProyecto"
            :loading="btnEnvioLoading"
            :disabled="datosPendientes > 0"
          >
            Sí, confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--begin:: dialog suspension -->
    <v-dialog
      v-model="dialogEnvio"
      width="500"
      transition="scroll-y-transition"
    >
      <v-card>
        <v-card-title class="text-h5">
          Enviar proyecto

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEnvioLoading"
            persistent
            transition="scroll-y-transition"
            @click="dialogEnvio = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="formAprobacion"
            v-on:submit.prevent="enviarProyecto"
            v-model="validFormEnvio"
          >
            <v-row>
              <v-col cols="12">
                <p class="text-h6 black--text">
                  {{ nombreProyecto }}
                </p>

                <p class="text-h6">
                  Está seguro de enviar el proyecto seleccionado para revisión?
                </p>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  rows="4"
                  color="blue-grey lighten-2"
                  v-model="comentarios"
                  label="Agregue sus comentarios"
                  :rules="[
                    required('comentarios'),
                    minLength('comentarios', 10),
                    maxLength('comentarios', 500)
                  ]"
                  maxlength="500"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <v-btn
                  color="blue-grey darken-2"
                  text
                  class="float-right"
                  :loading="btnEnvioLoading"
                  type="submit"
                  :disabled="!validFormEnvio"
                >
                  Sí, enviar
                </v-btn>

                <v-btn
                  color="blue-grey darken-2"
                  text
                  class="float-right"
                  @click="dialogEnvio = false"
                  :disabled="btnEnvioLoading"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--begin:: dialog aprobacion -->
    <v-dialog
      v-model="dialogAprobacion"
      persistent
      width="500"
      transition="scroll-y-transition"
    >
      <v-card>
        <v-card-title class="text-h5">
          Aprobar proyecto

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnAprobacionLoading"
            transition="dialog-bottom-transition"
            @click="dialogAprobacion = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="formAprobacion"
            v-on:submit.prevent="aprobarProyecto(1)"
            v-model="validFormAprobacion"
          >
            <v-row>
              <v-col cols="12">
                <p class="text-subtitle-1">
                  Está seguro de aprobar el proyecto seleccionado?
                </p>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      autocomplete="off"
                      class="required"
                      dense
                      filled
                      rows="4"
                      color="blue-grey lighten-2"
                      v-model="comentariosAprobacion"
                      label="Agregue sus comentarios"
                      :rules="[
                        required('comentarios'),
                        minLength('comentarios', 5),
                        maxLength('comentarios', 500)
                      ]"
                      maxlength="500"
                    >
                    </v-textarea>
                    <!--<div
                    class="blue-grey lighten-5 pa-4 text-center secondary rounded-lg"
                  >
                    <p class="text-subtitle-1">
                      {{ datosItem.descripcion }}
                    </p>
                  </div>-->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-btn
                  color="teal--text lighten-1--text"
                  text
                  class="float-right"
                  type="submit"
                  :loading="btnAprobacionLoading"
                  :disabled="!validFormAprobacion"
                >
                  <v-icon left>mdi-check-all</v-icon>
                  Sí, aprobar
                </v-btn>
                <v-btn
                  color="blue-grey darken-2"
                  text
                  class="float-right"
                  @click="dialogAprobacion = false"
                  :disabled="btnAprobacionLoading"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--begin:: dialog suspension -->
    <v-dialog
      v-model="dialogRechazo"
      persistent
      width="500"
      transition="scroll-y-transition"
    >
      <v-card>
        <v-card-title class="text-h5">
          Rechazar proyecto

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnAprobacionLoading"
            transition="dialog-bottom-transition"
            @click="
              dialogRechazo = false;
              comentarios = '';
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="formRechazo"
            v-on:submit.prevent="aprobarProyecto(2)"
            v-model="validFormRechazo"
          >
            <v-row>
              <v-col cols="12">
                <p class="text-subtitle-1">
                  Está seguro de rechazar el proyecto seleccionado?
                </p>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      autocomplete="off"
                      class="required"
                      dense
                      filled
                      rows="4"
                      color="blue-grey lighten-2"
                      v-model="comentariosRechazo"
                      label="Agregue sus comentarios"
                      :rules="[
                        required('comentarios'),
                        minLength('comentarios', 5),
                        maxLength('comentarios', 500)
                      ]"
                      maxlength="500"
                    >
                    </v-textarea>
                    <!--<div
                    class="blue-grey lighten-5 pa-4 text-center secondary rounded-lg"
                  >
                    <p class="text-subtitle-1">
                      {{ datosItem.descripcion }}
                    </p>
                  </div>-->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-btn
                  color="red--text darken-1--text"
                  text
                  class="float-right"
                  type="submit"
                  :loading="btnAprobacionLoading"
                  :disabled="!validFormRechazo"
                >
                  <v-icon>mdi-close</v-icon>
                  Sí, rechazar
                </v-btn>

                <v-btn
                  color="blue-grey darken-2"
                  text
                  class="float-right"
                  @click="dialogRechazo = false"
                  :disabled="btnAprobacionLoading"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--end: dialog rechazo-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";

import {
  OBTENER_DATOS_SECCIONES_PROYECTO_CNSCOO,
  ENVIAR_CNSC
} from "@/core/services/store/proyectoscooperantes/proyectocooperantes.module";

import {
  OBTENER_PROYECTOS_CDES,
  OBTENER_AVANCE_PROYECTO_CDES,
  CONFIRMAR_PROYECTO_CDES
} from "@/core/services/store/proyectoscooperaciondescentralizada/proyectocooperaciondescentralizada.module";

import validations from "@/core/untils/validations.js";

import SeccionAlcanceProyecto from "@/view/pages/proyectos-cns/externo/SeccionAlcanceProyecto.vue";
import SeccionDatosGeneralesProyectoDescentralizada from "@/view/pages/proyectos-cooperacion-descentralizada/secciones/SeccionDatosGeneralesProyectoDescentralizada.vue";
import SeccionActorImplementadorProyectoCooperante from "@/view/pages/proyectos-cooperacion/secciones/SeccionActorImplementadorProyectoCooperante.vue";
import SeccionFuenteCooperanteProyectosDescentralizada from "@/view/pages/proyectos-cooperacion-descentralizada/secciones/SeccionFuenteCooperanteProyectosDescentralizada.vue";
//import SeccionDatosFinancierosCooperante from "@/view/pages/proyectos-cooperacion/secciones/SeccionDatosFinancierosCooperante.vue";
import SeccionPlazosProyecto from "@/view/pages/proyectos-cns/externo/SeccionPlazosProyecto.vue";
import SeccionVinculacionEstrategicaProyectoCooperacion from "@/view/pages/proyectos-cooperacion-descentralizada/secciones/SeccionVinculacionEstrategicaProyectoCooperacion.vue";
import SeccionBeneficiariosProyectoCooperacionDesc from "@/view/pages/proyectos-cooperacion-descentralizada/secciones/SeccionBeneficiariosProyectoCooperacionDesc.vue";
import SeccionContactosProyectoCooperante from "@/view/pages/proyectos-cooperacion/secciones/SeccionContactosProyectoCooperante.vue";
import SeccionDatosInstitucionCooperacionDesc from "@/view/pages/proyectos-cooperacion-descentralizada/secciones/SeccionDatosInstitucionCooperacionDesc.vue";
import SeccionConfirmarProyectoCooperacionDesc from "@/view/pages/proyectos-cooperacion-descentralizada/secciones/SeccionConfirmarProyectoCooperacionDesc.vue";
moment.locale("es");
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

export default {
  name: "AdminInformacionProyectosCooperacionExterno",
  components: {
    SnackAlert,
    DialogLoader,
    SeccionAlcanceProyecto,
    SeccionDatosGeneralesProyectoDescentralizada,
    SeccionFuenteCooperanteProyectosDescentralizada,
   // SeccionDatosFinancierosCooperante,
    SeccionActorImplementadorProyectoCooperante,
    SeccionPlazosProyecto,
    SeccionVinculacionEstrategicaProyectoCooperacion,
    SeccionBeneficiariosProyectoCooperacionDesc,
    SeccionContactosProyectoCooperante,
    SeccionDatosInstitucionCooperacionDesc,
    SeccionConfirmarProyectoCooperacionDesc
  },
  data() {
    return {
      cooperanteId: 1, /////////////////////////////////////////////////ACTUALIZAR CON EL COOPERANTE LOGUEADO
      validForm: false,
      ddRolesLoading: false,
      switchItemEstado: true,
      dialogRegistro: false,
      dialogConfirmacion: false,
      btnEnvioLoading: false,
      tableLoading: false,
      dialogSuspension: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "Obteniendo información del proyecto...",
      skeletonAvance: [],
      tab: null,
      tabConfirmacion: 1,
      proyectoSeleccionado: "",
      idProyectoSeleccionado: 0,
      filtro: "",
      dialog: false,
      modalTitle: "Registrar nuevo proyecto",
      modalTitleConfirmar: "",
      accion: 1,
      btnRegistroText: "Guardar",
      btnRegistroLoading: false,
      estadoProyecto: "",
      proyectos: [],
      idProyecto: 0,
      codigoProyectoCNS: "",
      codigoProyecto: "",
      estadosProyecto: [
        { text: "Todos", value: null },
        { value: 1, text: "Registro" },
        { value: 2, text: "Seguimiento" },
        { value: 6, text: "Finalizado" },
        { value: 7, text: "Suspendido" }
      ],

      menuAvanceProyecto: [{ id: 1, show: false }],
      datosProyecto: [],
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 0
      },
      informacionGeneralRegistrada: false,
      datosFinancierosRegistrados: false,
      vinculacionEstrategicaRegistrada: false,
      beneficiariosRegistrados: false,
      datosInstitucionRegistrados: false,
      alcanceRegistrado: false,
      contactosRegistrados: false,
      fechasPlazosRegistradas: false,
      coberturaRegistrada: false,
      documentoJustificacionRegistrado: false,
      documentoOpiniontecnicaRegistrado: false,
      documentoProyectoRegistrado: false,
      documentoConvenioRegistrado: false,
      datosPendientes: 0,
      porcentajeAvance: 0,
      tipo: 1, //Se utiliza para definir si la información se debe solo mostrar para el admin segeplan (1) o mostrar y poder editar por la fuente cooperante (2)
      dialogEnvio: false,
      datosEnvio: {
        id: 0,
        estadoId: 0,
        observaciones: ""
      },
      comentarios: "",
      comentariosAprobacion: "",
      comentariosRechazo: "",
      comentariosCorreccion: "",
      btnAprobacionLoading: false,
      nombreProyecto: "",
      datosEstado: {},
      validFormAprobacion: false,
      validFormRechazo: false,
      dialogAprobacion: false,
      dialogRechazo: false,
      datosAvanceProyecto: {},
      ...validations
    };
  },

  methods: {

    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estadoProyecto.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estadoProyecto.value;
    },

    //Obtener proyectos
    async obtenerProyectos() {
      this.proyectos = [];
      this.tableLoading = true;
      //let estadoProyectoId = 2;

      this.$store
        .dispatch(OBTENER_PROYECTOS_CDES, { estadoId: 0 })
        .then(res => {
          if (res.status === 200) {
            this.proyectos = res.data;
          }
          //console.log(this.items)
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },

    modalNuevo() {
      this.modalTitle = "Registrar proyecto";
      this.codigoProyecto = "";
      this.tab = "tab-1";
      this.accion = 1;

      if (this.$refs.SeccionDatosGeneralesProyectoDescentralizada) {
        this.$refs.SeccionDatosGeneralesProyectoDescentralizada.resetItems(
          1,
          null
        );
      }
      /*    this.$refs.SeccionDatosGeneralesProyectoDescentralizada.obtenerTipoFuenteUsuario();
            

            this.$refs.SeccionDatosGeneralesProyectoDescentralizada.obtenerSectoresBeneficiados();
            this.$refs.SeccionDatosGeneralesProyectoDescentralizada.obtenerTiposFuenteCooperante();
            this.$refs.SeccionDatosGeneralesProyectoDescentralizada.obtenerFuentesMultilateral();
            //this.$refs.seccionInformacionGeneralProyectoCooperacion.obtenerFuentesBilaterales();
        }*/

      if (this.$refs.seccionDatosFinancierosCooperante) {
        //this.$refs.seccionDatosFinancierosCooperante.obtenerMontoSuscrito(this.idProyecto);
        this.$refs.seccionDatosFinancierosCooperante.resetItems();
        this.$refs.seccionDatosFinancierosCooperante.obtenerMonedas();
        this.$refs.seccionDatosFinancierosCooperante.obtenerPeriodos();
        //this.$refs.seccionDatosFinancierosCooperante.obtenerProyecciones(this.idProyecto);
      }

      if (this.$refs.SeccionFuenteCooperanteProyectosDescentralizada) {
        this.$refs.SeccionFuenteCooperanteProyectosDescentralizada.resetItems(
          1,
          null
        );
      }

      if (this.$refs.seccionVinculacionEstrategicaProyectoCooperacion) {
        this.$refs.seccionVinculacionEstrategicaProyectoCooperacion.resetItems(
          1,
          null
        );
      }

      if (this.$refs.SeccionBeneficiariosProyectoCooperacionDesc) {
        this.$refs.SeccionBeneficiariosProyectoCooperacionDesc.resetItems(2);
      }

      if (this.$refs.SeccionDatosInstitucionCooperacionDesc) {
        this.$refs.SeccionDatosInstitucionCooperacionDesc.resetItems(1, null);
      }


     /*  if (this.$refs.SeccionConfirmarProyectoCooperacionDesc) {
        this.$refs.SeccionConfirmarProyectoCooperacionDesc.resetItems(1, null);
      } */

      if (this.$refs.seccionActorImplementadorProyectoCooperante) {
        this.$refs.seccionActorImplementadorProyectoCooperante.resetItems(2);
      }

      this.dialogRegistro = true;
    },

    mostrarModalActualizar(id, codigo) {
      this.dialogLoaderText = "Obteniendo información del proyecto...";
      this.dialogLoaderVisible = true;
      this.modalTitle = "Actualizar proyecto | ";
      this.accion = 2;
      this.tab = "tab-1";
      this.idProyecto = id;
      //console.log(this.idProyecto)
      this.codigoProyecto = codigo;
      //console.log(test);
      if (this.$refs.SeccionDatosGeneralesProyectoDescentralizada) {
        this.$refs.SeccionDatosGeneralesProyectoDescentralizada.resetItems(
          2,
          id
        );

        //this.$refs.SeccionDatosGeneralesProyectoDescentralizada.obtenerDatosItem(this.idProyecto).then(()=> {
        //this.dialogLoaderVisible = false;
        //this.$refs.SeccionDatosGeneralesProyectoDescentralizada.obtenerTipoFuenteUsuario();

        //this.dialogRegistro = true;

        /* this.$refs.seccionInformacionGeneralProyectoCooperacion.obtenerFuentesBilaterales().then(() => {
            this.$refs.seccionInformacionGeneralProyectoCooperacion.obtenerFuentesMultilateral().then(() => {
              this.$refs.seccionInformacionGeneralProyectoCooperacion.obtenerDatosItem(this.idProyecto);  
            }); 
          });*/
        // })
      } else {
        setTimeout((this.dialogRegistro = true), 1000);
      }

      if (this.$refs.SeccionFuenteCooperanteProyectosDescentralizada) {
        this.$refs.SeccionFuenteCooperanteProyectosDescentralizada.resetItems(
          2,
          id
        );
      } else {
        setTimeout((this.dialogRegistro = true), 1000);
      }

      if (this.$refs.seccionAlcance) {
        this.$refs.seccionAlcance.resetItems();
        this.$refs.seccionAlcance.obtenerDatosItem(this.idProyecto);
      }

      if (this.$refs.seccionDatosFinancierosCooperante) {
        this.$refs.seccionDatosFinancierosCooperante.resetItems();
        this.$refs.seccionDatosFinancierosCooperante.obtenerMontoSuscrito(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancierosCooperante.obtenerMonedas();
        this.$refs.seccionDatosFinancierosCooperante.obtenerPeriodos();

        this.$refs.seccionDatosFinancierosCooperante.obtenerProyecciones(
          this.idProyecto
        );

        /*
        this.$refs.seccionDatosFinancierosCooperante.obtenerTiposDatosFinancieros();
        this.$refs.seccionDatosFinancierosCooperante.obtenerTiposDatosFinancierosDistribucion();
        this.$refs.seccionDatosFinancierosCooperante.obtenerDatosEspecificosProyecto(this.idProyecto);
        this.$refs.seccionDatosFinancierosCooperante.obtenerDatosDistribucionFuenteCooperante(this.idProyecto);
        this.$refs.seccionDatosFinancierosCooperante.obtenerProyeccionesDesembolsos(this.idProyecto);
        this.$refs.seccionDatosFinancierosCooperante.obtenerMontosSuscritosDepto(this.idProyecto); 
        this.$refs.seccionDatosFinancierosCooperante.obtenerEjecucionesPorActividad(this.idProyecto); 
        this.$refs.seccionDatosFinancierosCooperante.obtenerEjecucionesPorActividadContrapartida(this.idProyecto);
        this.$refs.seccionDatosFinancierosCooperante.obtenerSubDivisiones();*/
      }

      if (this.$refs.seccionVinculacionEstrategicaProyectoCooperacion) {
        this.$refs.seccionVinculacionEstrategicaProyectoCooperacion.resetItems(
          2,
          this.idProyecto
        );
      }

      if (this.$refs.seccionCoberturaVinculacion) {
        this.$refs.seccionCoberturaVinculacion.resetItems(1);
        this.$refs.seccionCoberturaVinculacion.obtenerSectoresBeneficiados();
        this.$refs.seccionCoberturaVinculacion.obtenerDepartamentos();
        this.$refs.seccionCoberturaVinculacion.obtenerPnds();
        this.$refs.seccionCoberturaVinculacion.obtenerOds();
        this.$refs.seccionCoberturaVinculacion.obtenerEjesKatun();
        this.$refs.seccionCoberturaVinculacion.obtenerPgg();
        this.$refs.seccionCoberturaVinculacion.obtenerActividades();
        this.$refs.seccionCoberturaVinculacion.obtenerCoberturaProyecto(
          this.idProyecto
        );
      }

      if (this.$refs.SeccionBeneficiariosProyectoCooperacionDesc) {
        this.$refs.SeccionBeneficiariosProyectoCooperacionDesc.resetItems(2, id);
      }

      if (this.$refs.SeccionDatosInstitucionCooperacionDesc) {
        this.$refs.SeccionDatosInstitucionCooperacionDesc.resetItems(2, this.idProyecto);
      }


      if (this.$refs.SeccionConfirmarProyectoCooperacionDesc) {
        this.$refs.SeccionConfirmarProyectoCooperacionDesc.obtenerAvanceProyectoCns(this.idProyecto);
      }


      //this.dialogLoaderVisible = false;
    },

    //Ocultar dialog loader
    mostrarDialogRegistro() {
      this.dialogLoaderVisible = false;
      setTimeout((this.dialogRegistro = true), 2000);
    },

    async mostrarAvanceProyecto(idProyecto) {
      this.datosAvanceProyecto = {};
      this.skeletonAvance[idProyecto] = true;
      this.obtenerAvanceProyectoCns(idProyecto).then(() => {
        this.verificarDatosSecciones(this.datosAvanceProyecto);
        this.skeletonAvance[idProyecto] = false;
      });
    },

    ///Obener el avance de registro del proyecto
    async obtenerAvanceProyectoCns(proyectoId) {
      this.datosAvanceProyecto = {};
      this.skeletonLoading = true;
      await this.$store
        .dispatch(OBTENER_AVANCE_PROYECTO_CDES, { id: proyectoId })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.datosAvanceProyecto = res.data;
            this.codigoProyecto = this.datosAvanceProyecto.codigo;
            //this.verificarDatosSecciones(this.datosAvanceProyecto);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Mensaje",
              res.message
            );
          }
          this.skeletonLoading = false;
        })
        .catch(() => {
          //console.log(error)
          this.skeletonLoading = false;
          this.dialogLoaderVisible = false;
          this.datosAvanceProyecto = {};
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    async obtenerDatosEspecificosProyecto(idProyecto) {
      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO_CNSCOO, {
          Seccion: "DatosGenerales",
          id: idProyecto
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.datosProyecto = res.data;
          }
          this.skeletonAvance[idProyecto] = false;
        })
        .catch(() => {
          //console.log(error)
          this.skeletonAvance[idProyecto] = false;
          this.dialogLoaderVisible = false;
          this.datosProyecto = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    cerrarModalRegistro() {
      //this.dialogRegistro = false;
      this.tab = "tab-1";
      //this.$refs.ModalRegistro.ocultarModal();
      let test = this.$options.components[
        "SeccionInformacionGeneralProyectoCooperacion"
      ];
      test.$destroy();
    },

    resetForm() {},
    ///Registro de usuario
    registrarItem() {
      return "Listo";
    },
    submit: function(e) {
      e.preventDefault();
      this.$refs.snackalert.SnackbarShow("success", "Mensaje", "test");
    },

    mostrarDialogConfirmacion(id, codigo) {
      this.dialogLoaderText = "Obteniendo información del proyecto...";
      this.dialogLoaderVisible = true;
      this.modalTitleConfirmar = "Confirmar proyecto ";
      this.idProyectoSeleccionado = id;
      this.accion = 2;
      this.tabConfirmacion = "tab-1";
      this.idProyecto = id;
      //console.log(this.idProyecto)
      this.codigoProyecto = codigo;
      //console.log(test);
      if (this.$refs.SeccionDatosGeneralesProyectoDescentralizadaConfirmacion) {
        this.$refs.SeccionDatosGeneralesProyectoDescentralizadaConfirmacion.resetItems(
          2,
          id
        );

        //this.$refs.SeccionDatosGeneralesProyectoDescentralizada.obtenerDatosItem(this.idProyecto).then(()=> {
        //this.dialogLoaderVisible = false;
        //this.$refs.SeccionDatosGeneralesProyectoDescentralizada.obtenerTipoFuenteUsuario();

        //this.dialogRegistro = true;

        /* this.$refs.seccionInformacionGeneralProyectoCooperacion.obtenerFuentesBilaterales().then(() => {
            this.$refs.seccionInformacionGeneralProyectoCooperacion.obtenerFuentesMultilateral().then(() => {
              this.$refs.seccionInformacionGeneralProyectoCooperacion.obtenerDatosItem(this.idProyecto);  
            }); 
          });*/
        // })
      } else {
        setTimeout((this.dialogRegistro = true), 1000);
      }

      if (
        this.$refs.SeccionFuenteCooperanteProyectosDescentralizadaConfirmacion
      ) {
        this.$refs.SeccionFuenteCooperanteProyectosDescentralizadaConfirmacion.resetItems(
          2,
          id
        );
      } else {
        setTimeout((this.dialogRegistro = true), 1000);
      }

      if (this.$refs.seccionAlcanceConfirmacion) {
        this.$refs.seccionAlcanceConfirmacion.resetItems();
        this.$refs.seccionAlcanceConfirmacion.obtenerDatosItem(this.idProyecto);
      }

      if (
        this.$refs.seccionVinculacionEstrategicaProyectoCooperacionConfirmacion
      ) {
        this.$refs.seccionVinculacionEstrategicaProyectoCooperacionConfirmacion.resetItems(
          2,
          this.idProyecto
        );
      }

      /*if (this.$refs.seccionCoberturaVinculacionConfirmacion) {
        this.$refs.seccionCoberturaVinculacion.resetItems(1);
        this.$refs.seccionCoberturaVinculacion.obtenerSectoresBeneficiados();
        this.$refs.seccionCoberturaVinculacion.obtenerDepartamentos();
        this.$refs.seccionCoberturaVinculacion.obtenerPnds();
        this.$refs.seccionCoberturaVinculacion.obtenerOds();
        this.$refs.seccionCoberturaVinculacion.obtenerEjesKatun();
        this.$refs.seccionCoberturaVinculacion.obtenerPgg();
        this.$refs.seccionCoberturaVinculacion.obtenerActividades();
        this.$refs.seccionCoberturaVinculacion.obtenerCoberturaProyecto(
          this.idProyecto
        );
      }*/

      if (this.$refs.SeccionBeneficiariosProyectoCooperacionDescConfirmacion) {
        this.$refs.SeccionBeneficiariosProyectoCooperacionDescConfirmacion.resetItems(2, id);
      }

      if (this.$refs.SeccionDatosInstitucionCooperacionDescConfirmacion) {
        this.$refs.SeccionDatosInstitucionCooperacionDescConfirmacion.resetItems(2, this.idProyecto);
      }


      /*if (this.$refs.SeccionConfirmarProyectoCooperacionDesc) {
        this.$refs.SeccionConfirmarProyectoCooperacionDesc.obtenerAvanceProyectoCns(this.idProyecto);
      }*/

      this.obtenerAvanceProyectoCns(id).then(() => {
        this.verificarDatosSecciones(this.datosAvanceProyecto);
        this.dialogLoaderVisible = false;
        this.dialogConfirmacion = true;
      });

      /*this.idProyectoSeleccionado = idProyecto;
      this.dialogLoaderVisible = true;
      this.codigoProyectoCNS = codigo;
      this.datosAvanceProyecto = {};
      this.obtenerAvanceProyectoCns(idProyecto).then(() => {
        this.verificarDatosSecciones(this.datosAvanceProyecto);
        this.dialogLoaderVisible = false;
        this.dialogConfirmacion = true;
      });*/
    },

    //Verificar los datos necesarios para poder confirmar el proyecto y devolver un listado
    verificarDatosSecciones(datosProyecto) {
      //console.log(datosProyecto)
      this.datosPendientes = 0;
      this.porcentajeAvance = datosProyecto.porcentajeAvance;
      if (this.porcentajeAvance < 100) {
        this.datosPendientes++;
      }

      datosProyecto.informacionGeneralRegistrada == 1
        ? (this.informacionGeneralRegistrada = true)
        : (this.informacionGeneralRegistrada = false);
      datosProyecto.datosFinancierosRegistrados == 1
        ? (this.datosFinancierosRegistrados = true)
        : (this.datosFinancierosRegistrados = false);
      datosProyecto.vinculacionEstrategicaRegistrada == 1
        ? (this.vinculacionEstrategicaRegistrada = true)
        : (this.vinculacionEstrategicaRegistrada = false);
      datosProyecto.beneficiariosRegistrados == 1
        ? (this.beneficiariosRegistrados = true)
        : (this.beneficiariosRegistrados = false);
      datosProyecto.datosInstitucionRegistrados == 1
        ? (this.datosInstitucionRegistrados = true)
        : (this.datosInstitucionRegistrados = false);
      datosProyecto.datosInstitucionRegistrados == 1
        ? (this.datosInstitucionRegistrados = true)
        : (this.datosInstitucionRegistrados = false);
    },

    async confirmarProyecto() {
      this.btnEnvioLoading = true;

      await this.$store
        .dispatch(CONFIRMAR_PROYECTO_CDES, {
          id: this.idProyectoSeleccionado,
          codigo: this.codigoProyectoCNS
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogRegistro = false;
            this.dialogConfirmacion = false;
            this.obtenerProyectos();
            this.nombreProyecto = "";
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Error!",
              res.message
            );
          }
          this.btnEnvioLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnEnvioLoading = false;
        });
    },

    prepararEnvio(id, nombre) {
      this.nombreProyecto = nombre;
      this.datosEnvio.id = id;
      this.dialogEnvio = true;
    },

    //Enviar el proyecto al administrador segeplan
    async enviarProyecto() {
      this.btnEnvioLoading = true;
      let datos = {};
      datos.estadoId = 2;
      datos.proyectoCNSCId = this.datosEnvio.id;
      datos.id = this.datosEnvio.id;
      datos.comentarios = this.comentarios;

      this.$store
        .dispatch(ENVIAR_CNSC, datos)
        .then(res => {
          this.btnEnvioLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerProyectos();
            this.dialogEnvio = false;
            this.comentarios = "";
            this.nombreProyecto = "";
            this.datosEnvio.id = 0;
            this.datosEnvio;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEnvioLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.btnEnvioLoading = false;
        });
    },

    actualizarAccion(accion) {
      this.accion = accion;
    },

    actualizarId(id, codigo) {
      this.idProyecto = id;
      this.codigoProyecto = codigo;
    },

    //Mostrar el dialogo para aprobacion
    mostrarDialogAprobacion(id, nombreProyecto) {
      this.datosEstado.nombreProyecto = nombreProyecto;
      this.datosEstado.id = id;
      this.datosEstado.estadoId = 3;
      this.dialogAprobacion = true;
    },

    //Mostrar el dialogo para rechazo
    mostrarDialogRechazo(id, nombreProyecto) {
      this.datosEstado.descripcion = nombreProyecto;
      this.datosEstado.id = id;
      this.datosEstado.estadoId = 4;
      this.dialogRechazo = true;
    },

    //Aprobar proyecto(1) o rechazar(2)
    async aprobarProyecto(tipo) {
      let datos = {};
      this.btnAprobacionLoading = true;

      if (tipo === 1) {
        datos.estadosProyectosCNSCId = 3;
        datos.comentarios = this.comentariosAprobacion;
      } else if (tipo == 2) {
        datos.estadosProyectosCNSCId = 4;
        datos.comentarios = this.comentariosRechazo;
      } else if (tipo === 3) {
        datos.estadosProyectosCNSCId = 5;
        datos.comentarios = this.comentariosCorreccion;
      }

      datos.proyectoCNSCId = this.datosEstado.id;
      datos.id = this.datosEstado.id;
      //datos.comentarios=this.comentarios;
      //datos.id = this.datosItem.id;

      this.$store
        .dispatch(ENVIAR_CNSC, datos)
        .then(res => {
          this.btnAprobacionLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerProyectos();

            if (tipo === 1) {
              this.dialogAprobacion = false;
              this.comentariosAprobacion = "";
            } else if (tipo == 2) {
              this.dialogRechazo = false;
              this.comentariosRechazo = "";
            } else if (tipo === 3) {
              (this.dialogCorreccion = false),
                (this.comentariosCorreccion = "");
            }
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnAprobacionLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.btnAprobacionLoading = false;
        });
    },

    cerrarDialogLoader() {
      this.dialogLoaderVisible = false;
    },
    //Actualizar el avance del proyecto
    actualizarAvanceProyecto(proyectoId) {
      if (this.$refs.SeccionConfirmarProyectoCooperacionDesc) {
        this.$refs.SeccionConfirmarProyectoCooperacionDesc.obtenerAvanceProyectoCns(
          proyectoId
        );
      }
    }
  },

  created() {
    // console.log(this.currentUser)
    this.obtenerProyectos();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/dashboard" },
      { title: "Proyectos" },
      { title: "Cooperación descentralizada" }
    ]);
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),

    headers() {
      return [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "codigo"
        },

        {
          text: "Nombre Proyecto",
          align: "start",
          sortable: true,
          value: "nombreProyecto"
        },
        {
          text: "Fecha de suscripcion",
          align: "start",
          sortable: true,
          value: "fechaSuscripcionDonacion"
        },
        {
          text: "Institución / Dependencia",
          align: "start",
          sortable: true,
          value: "nombreInstitucion"
        },
        {
          text: "Sector Beneficiado",
          align: "start",
          sortable: true,
          value: "sector"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estadoProyectoId",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    }
  }
};
</script>
