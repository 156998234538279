<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="!skeletonLoading">
      <v-row>
        <v-col cols="12" md="12" sm="12" class="pt-0 pb-1">
          <v-subheader class="text-h5 black--text">
            Plazos del proyecto
          </v-subheader>
        </v-col>
      </v-row>

      <v-row class="pb-3">
        <v-col cols="12" md="12" sm="12">
          <v-alert outlined color="#1488c2" border="left">
            <p>
              Los campos marcados con un asterisco (<span
                color="red"
                class="red--text"
                >*</span
              >) son obligatorios
            </p>
          </v-alert>
        </v-col>
      </v-row>

      <v-card class="mt-2" outlined>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0">
              <v-subheader class="text-h6 black--text">
                Fechas
              </v-subheader>
              <v-divider class="mt-0 pt-0 pb-2"></v-divider>
            </v-col>

            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-form
                ref="formProyeccionDesembolsos"
                v-on:submit.prevent="agregarFechas"
                v-model="validFormGeneral"
              >
                <v-row>
                  <!-- <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                                <v-text-field
                                    dense
                                    filled
                                    autocomplete="off"
                                    class="required"
                                    color="blue-grey lighten-2"
                                    hint="dia/mes/año"
                                    v-model="fechaSuscripcionPlazos"
                                    label="Fecha de suscripción del proyecto"
                                    :rules="[
                                        required('fecha suscripción'),
                                        dateFormat('fecha suscripción')
                                    ]"
                                    maxlength="10"
                                    v-mask="'##/##/####'"
                                    ></v-text-field>
                            </v-col> -->

                  <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                    <v-menu
                      ref="menuFechaSuscripcion"
                      v-model="menuFechaSuscripcion"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          color="blue-grey lighten-2"
                          v-model="computedFechaSuscripcion"
                          label="Fecha de suscripción"
                          hint="DD/MM/AAAA"
                          persistent-hint
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          :rules="[required('fecha suscripción')]"
                          v-mask="'##/##/####'"
                          maxlength="10"
                          :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateFechaSuscripcion"
                        no-title
                        @input="menuFechaSuscripcion = false"
                        locale="es"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                    <v-menu
                      ref="menuFechaInicio"
                      v-model="menuFechaInicio"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          color="blue-grey lighten-2"
                          v-model="computedFechaInicio"
                          label="Fecha de inicio"
                          hint="DD/MM/AAAA"
                          persistent-hint
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          :rules="[
                            required('fecha de inicio'),
                            /* dateEqualGreatherThan('fecha de inicio', computedFechaSuscripcion),*/
                          ]"
                          v-mask="'##/##/####'"
                          maxlength="10"
                          :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateFechaInicio"
                        no-title
                        @input="menuFechaInicio = false"
                        locale="es"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                    <v-menu
                      ref="menuFechaAprobacion"
                      v-model="menuFechaAprobacion"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          color="blue-grey lighten-2"
                          v-model="computedFechaAprobacion"
                          label="Fecha de aprobación (acuerdo gubernativo, ministerial o resolución)"
                          hint="DD/MM/AAAA"
                          persistent-hint
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          :rules="[
                            required('fecha de aprobación'),
                            /*dateEqualGreatherThan('fecha de inicio', computedFechaSuscripcion),*/
                          ]"
                          v-mask="'##/##/####'"
                          maxlength="10"
                          :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateFechaAprobacion"
                        no-title
                        @input="menuFechaAprobacion = false"
                        locale="es"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                    <v-menu
                      ref="menuFechaFinalizacionEjecucion"
                      v-model="menuFechaFinalizacionEjecucion"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          readonly
                          color="blue-grey lighten-2"
                          v-model="computedFechaFinalizacionEjecucion"
                          label="Fecha de finalización fase de ejecución"
                          hint="DD/MM/AAAA"
                          persistent-hint
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          :rules="[
                            required('fecha de finalización fase de ejecución'),
                            dateEqualGreatherThan(
                              'fecha de inicio',
                              computedFechaInicio
                            ),
                          ]"
                          v-mask="'##/##/####'"
                          maxlength="10"
                          :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateFechaFinalizacionEjecucion"
                        no-title
                        @input="menuFechaFinalizacionEjecucion = false"
                        locale="es"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                    <v-menu
                      ref="menuFechaFinPlazos"
                      v-model="menuFechaFinPlazos"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          color="blue-grey lighten-2"
                          v-model="computedFechaFinalizacionPlazos"
                          label="Fecha de finalización"
                          hint="DD/MM/AAAA"
                          persistent-hint
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          :rules="[
                            required('fecha de aprobación'),
                            dateEqualGreatherThan(
                              'fecha de inicio',
                              computedFechaInicio
                            ),
                          ]"
                          v-mask="'##/##/####'"
                          maxlength="10"
                          :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateFechaFinalizacion"
                        no-title
                        @input="menuFechaFinPlazos = false"
                        locale="es"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <!-- <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                                <v-text-field
                                    dense
                                    filled
                                    autocomplete="off"
                                    class="required"
                                    color="blue-grey lighten-2"
                                    hint="dia/mes/año"
                                    v-model="fechaInicioPlazos"
                                    label="Fecha de inicio"
                                    :rules="[
                                        required('fecha de inicio'),
                                        dateFormat('fecha de inicio'),
                                        dateEqualGreatherThan('fecha de inicio', fechaSuscripcionPlazos),
                                    ]"
                                    maxlength="10"
                                    v-mask="'##/##/####'"
                                    ></v-text-field>
                            </v-col> -->

                  <!-- <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                                <v-text-field
                                    dense
                                    filled
                                    autocomplete="off"
                                    class="required"
                                    color="blue-grey lighten-2"
                                    hint="dia/mes/año"
                                    v-model="fechaAprobacionPlazos"
                                    label="Fecha de aprobación (acuerdo gubernativo/ministerial/resolución)"
                                    :rules="[
                                        required('fecha de aprobación'),
                                        dateFormat('fecha de aprobación'),
                                        dateEqualGreatherThan('fecha de inicio', fechaSuscripcionPlazos),
                                    ]"
                                    maxlength="10"
                                    v-mask="'##/##/####'"
                                    ></v-text-field>
                            </v-col> -->

                  <!-- <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                                <v-text-field
                                    dense
                                    filled
                                    autocomplete="off"
                                    class="required"
                                    color="blue-grey lighten-2"
                                    hint="dia/mes/año"
                                    v-model="fechaFinalizacionFaseEjecucion"
                                    label="Fecha de finalización fase de ejecución"
                                    :rules="[
                                        required('fecha de finalización fase de ejecución'),
                                        dateFormat('fecha de finalización fase de ejecución'),
                                        dateGreatherThan('fecha de inicio', fechaSuscripcionPlazos),
                                    ]"
                                    maxlength="10"
                                    v-mask="'##/##/####'"
                                    ></v-text-field>
                            </v-col>
 -->
                  <!-- <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                                <v-text-field
                                    dense
                                    filled
                                    autocomplete="off"
                                    class="required"
                                    color="blue-grey lighten-2"
                                    hint="dia/mes/año"
                                    v-model="fechaFinalizacionPlazos"
                                    label="Fecha de finalización"
                                    :rules="[
                                        required('fecha de finalización'),
                                        dateFormat('fecha de finalización'),
                                        dateGreatherThan('fecha de inicio', fechaSuscripcionPlazos),
                                    ]"
                                    maxlength="10"
                                    v-mask="'##/##/####'"
                                    ></v-text-field>
                            </v-col> -->
                </v-row>

                <v-row v-if="1 > 1">
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-alert
                      outlined
                      type="error"
                      color="orange darken-1"
                      dense
                    >
                      Si la moneda es Quetzales (GTQ) el tipo de cambio debe ser
                      igual a 1
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row v-if="!seccionesBloqueadas">
                  <v-col cols="12" md="12" sm="12" class="pt-0">
                    <v-btn
                      color="light-blue-502"
                      class="white--text mb-2 float-right"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormGeneral"
                      :loading="btnRegistroLoading"
                    >
                      Guardar información
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-row v-if="enmiendaRegistrada" class="mt-4">
            <v-col cols="12" md="12">
              <v-alert
                text
                dense
                color="teal"
                icon="mdi-calendar"
                border="left"
              >
                Existe una enmienda aplicada para fechas del proyecto!
              </v-alert>

              <v-list-item two-line v-if="!skeletonLoading">
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1 font-weight-bold"
                    >Fecha de fase de ejecución actual</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    fechaFaseEjecucionActual
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line v-if="!skeletonLoading">
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1 font-weight-bold"
                    >Fecha de finalización actual</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    fechaFinalizacionActual
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!--fin:: seccion de de datos generales de plazos-->

      <!--inicio:: condiciones de desembolsos -->
      <v-card class="mt-6" outlined v-if="habilitarSeccionCondicionesDes">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
              <v-subheader class="text-h6 black--text">
                Condiciones de desembolsos
              </v-subheader>
            </v-col>
          </v-row>

          <v-form
            ref="formProyeccionDesembolsos"
            v-on:submit.prevent="
              actualizarCuentaCondicionesDesembolso(1, null, null)
            "
            v-model="validFormCuentaDesembolsos"
          >
            <v-row>
              <v-col cols="12" md="8" sm="8" xs="12" class="pt-6 pb-0">
                <v-select
                  v-model="cuentaCondicionDesembolsoSeleccionada"
                  :items="condicionesDesembolsosItems"
                  :loading="false"
                  dense
                  filled
                  class="required"
                  return-object
                  label="¿Cuenta con condiciones de desembolsos?"
                  item-text="text"
                  item-value="id"
                  menu-props="auto"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                ></v-select>
              </v-col>

              <v-col 
                cols="12"
                md="4"
                sm="4"
                xs="12"
                class="pt-6 pb-0"
                v-if="!seccionesBloqueadas"
              >
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormCuentaDesembolsos"
                  :loading="cuentaDesembolsosLoading"
                >
                  Guardar información
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-col cols="12" class="pt-0">
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>

            <v-col
              cols="12"
              md="12"
              sm="12"
              class="pt-6 pb-0"
              v-if="habilitarCondicionesDesembolsos"
            >
              <v-form
                ref="formProyeccionDesembolsos"
                v-on:submit.prevent="agregarCondicionesDesembolsos"
                v-model="validFormCondiciones"
              >
                <v-row>
                  <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                    <v-select
                      v-model="
                        datosCondicionDesembolso.tipoCondicionDesembolsoId
                      "
                      :items="tiposCondicionesDesembolso"
                      :loading="false"
                      dense
                      filled
                      class="required"
                      label="Condicionado por"
                      item-text="condicionadoPor"
                      item-value="id"
                      menu-props="auto"
                      :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      hint="Detalle el número de desembolsos para el proyecto"
                      v-model="datosCondicionDesembolso.numeroDesembolsos"
                      label="Número de desembolsos"
                      :rules="[
                        required('número de desembolsos'),
                        onlyInteger('número de desembolsos'),
                      ]"
                      maxlength="11"
                      v-mask="'###########'"
                      :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-textarea
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      hint="Detalle la condición"
                      v-model="datosCondicionDesembolso.condicion"
                      label="Condición"
                      rows="3"
                      :rules="[required('condición')]"
                      maxlength="2000"
                      :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-textarea
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      hint="Detalle el tiempo de cumplimiento"
                      v-model="datosCondicionDesembolso.tiempoCumplimiento"
                      label="Tiempo de cumplimiento"
                      rows="3"
                      :rules="[required('tiempo de cumplimiento')]"
                      :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                      maxlength="2000"
                    ></v-textarea>
                  </v-col>

                  <!--  <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                               <v-textarea
                                    dense
                                    filled
                                    autocomplete="off"
                                    class="required"
                                    color="blue-grey lighten-2"
                                    hint="Describa la forma de operar"
                                    v-model="datosCondicionDesembolso.formaOperar"
                                    label="Forma de operar"
                                    rows="3"
                                    :rules="[
                                        required('forma de operar'),
                                    ]"
                                    maxlength="2000"
                                    ></v-textarea>
                            </v-col>
 -->
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0">
                    <v-btn
                      color="light-blue-502"
                      class="white--text mb-2 float-right"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormCondiciones"
                      :loading="btnRegistroCondicionesLoading"
                    >
                      Guardar información
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>

            <!--inicio:: tabla condiciones desembolsos-->
            <v-col
              cols="12"
              md="12"
              sm="12"
              class="pt-6"
              v-if="habilitarCondicionesDesembolsos"
            >
              <v-data-table
                class="elevation-1"
                :headers="headersCondiciones"
                :items="condicionesDesembolsos"
                :loading="tableCondicionesLoading"
                no-data-text="No se encontraron registros"
                hide-default-footer
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}',
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.condicionadoPor }}</td>
                    <td class="text-right">{{ item.numeroDesembolsos }}</td>
                    <td>{{ item.condicion }}</td>
                    <td>{{ item.tiempoCumplimiento }}</td>
                    <!-- <td>{{ item.formaOperar }}</td> -->
                    <td>
                      <v-btn
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        color="blue-grey lighten-5"
                        :disabled="btnEliminarCondicionDisabled"
                        @click="eliminarCondicionDesembolso(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de condiciones de desembolso
                  </p>
                </template>
              </v-data-table>
            </v-col>
            <!--fin:: tabla condiciones desembolsos -->

            <v-col
              cols="12"
              md="12"
              sm="12"
              class="pt-6 pb-0"
              v-if="!habilitarCondicionesDesembolsos"
            >
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span class="card-label text-h6 font-weight-bolder text-dark">
                    Forma de operar</span
                  >
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-divider class="mt-0 pt-3 pb-2"></v-divider>
                </v-col>
              </v-row>

              <v-form
                ref="formFormaOperar"
                v-on:submit.prevent="registrarFormaOperar"
                v-model="validFormFormaOperar"
              >
                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-textarea
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      hint="Describa la forma de operar"
                      v-model="datosFormaOperar.detalleFormaOperar"
                      label="Detalle la forma de operar"
                      rows="3"
                      :rules="[
                        required('forma de operar'),
                        minLength('forma de operar', 5),
                        maxLength('forma de operar', 2000)
                      ]"
                      maxlength="2000"
                      :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-if="!seccionesBloqueadas">
                  <v-col cols="12" md="12" sm="12" class="pt-0">
                    <v-btn
                      color="light-blue-502"
                      class="white--text mb-2 float-right"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormFormaOperar"
                      :loading="btnFormaOperar"
                    >
                      Guardar información
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!--fin:: condiciones de desembolsos -->

      <!--Inicio:: Snack alert-->
      <SnackAlert ref="snackalert"></SnackAlert>
      <!-- Fin:: Snack alert-->

      <!--Dialog loader -->
      <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
      ></DialogLoader>
      <!---->
    </div>
  </div>
</template>

<script>
//import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import {
  OBTENER_DATOS_SECCIONES_PROYECTO,
  REGISTRAR_PROYECTO_CNS_EXTERNO,
  ACTUALIZAR_CUENTA_CONDICIONES_DESEMBOLSO_CNS,
  ACTUALIZA_FORMA_OPERAR_CNS,
} from "@/core/services/store/proyectoscns/proyectocns.module";
import {
  OBTENER_CONDICIONES_DESEMBOLSOS,
  REGISTRAR_CONDICION_DESEMBOLSO,
  ELIMINAR_CONDICION_DESEMBOLSO,
} from "@/core/services/store/proyectoscns/condicionesdesembolsos/condiciondesembolso.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";

export default {
  name: "SeccionPlazosProyecto",
  props: ["id", "tipoUsuario", "confirmacion"], ////Tipo de usuario puede ser ext (externo) o int (interno administrador), confirmacion (si se van a bloquear los elementos porque solo se van a visualizar)
  data() {
    return {
      dateFechaSuscripcion: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateFechaInicio: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateFechaAprobacion: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateFechaFinalizacionEjecucion: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateFechaFinalizacion: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menuFechaSuscripcion: false,
      menuFechaInicio: false,
      menuFechaAprobacion: false,
      menuFechaFinalizacionEjecucion: false,
      menuFechaFinPlazos: false,
      fechaActual: "",
      skeletonLoading: false,
      tableLoadingDesembolsos: false,
      tableCondicionesLoading: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      validFormGeneral: false,
      validFormCondiciones: false,
      validFormFormaOperar: false,
      btnRegistroLoading: false,
      btnRegistroCondicionesLoading: false,
      btnEliminarCondicionDisabled: false,
      cuentaCondicionDesembolsoSeleccionada: { id: 2, text: "NO" },
      fechaSuscripcionPlazos: "",
      fechaInicioPlazos: "",
      fechaAprobacionPlazos: "",
      fechaFinalizacionFaseEjecucion: "",
      fechaFinalizacionPlazos: "",
      datosProyecto: {},
      btnFormaOperar: false,
      datosFormaOperar: {
        proyectosCNSId: this.id,
        detalleFormaOperar: "",
      },
      datosFechas: {
        fechaSuscripcionPlazos: "",
        fechaInicioPlazos: "",
        fechaAprobacionPlazos: "",
        fechaFinalizacionFaseEjecucion: "",
        fechaFinalizacionPlazos: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      datosItem: {},
      datosCondicionDesembolso: {
        proyectosCNSId: this.id,
        tipoCondicionDesembolsoId: 0,
        numeroDesembolsos: "",
        condicion: "",
        tiempoCumplimiento: "",
        formaOperar: "",
        usuarioCreacion: "admin",
        estadosId: 1
      },
      condicionesDesembolsosItems: [
        { id: 2, text: "NO" },
        { id: 1, text: "SI" }
      ],
      condicionesDesembolsos: [],
      tiposCondicionesDesembolso: [],
      condicionadoPor: {},
      validFormCuentaDesembolsos: false,
      cuentaDesembolsosLoading: false,
      habilitarCondicionesDesembolsos: false,
      habilitarSeccionCondicionesDes: false,
      datosCuentaDesembolsos: {
        proyectosCNSId: this.id,
        condicionesDesembolsos: 0,
        usuarioActualizacion: "admin"
      },
      enmiendaRegistrada: false,
      fechaFaseEjecucionActual: "",
      fechaFinalizacionActual: "",
      alertFechas: false,
      seccionesBloqueadas: false,
      ...validations
    };
  },

  components: {
    DialogLoader,
    SnackAlert,
  },

  methods: {
    //Restablecer la información
    resetItems(proyectoId) {
      this.id = proyectoId;
      let currentDate = new Date().toJSON().slice(0, 10);
      this.fechaActual = moment(currentDate, "YYYY-MM-DD").format("DD-MM-YYYY");

      this.dateFechaSuscripcion = null;
      this.dateFechaInicio = null;
      this.dateFechaAprobacion = null;
      this.dateFechaFinalizacionEjecucion = null;
      this.dateFechaFinalizacion = null;

      this.enmiendaRegistrada = false;
      this.fechaFaseEjecucionActual = "";
      this.fechaFinalizacionActual = "";

      this.fechaSuscripcionPlazos = "";
      this.fechaInicioPlazos = "";
      this.fechaAprobacionPlazos = "";
      this.fechaFinalizacionFaseEjecucion = "";
      this.fechaFinalizacionPlazos = "";

      this.cuentaCondicionDesembolsoSeleccionada = { id: 2, text: "NO" };

      this.datosFechas = {
        fechaSuscripcionPlazos: "",
        fechaInicioPlazos: "",
        fechaAprobacionPlazos: "",
        fechaFinalizacionFaseEjecucion: "",
        fechaFinalizacionPlazos: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      };

      this.datosCuentaDesembolsos = {
        proyectosCNSId: proyectoId,
        condicionesDesembolsos: 0,
        usuarioActualizacion: "admin"
      };

      this.datosCondicionDesembolso = {
        proyectosCNSId: proyectoId,
        tipoCondicionDesembolsoId: 0,
        numeroDesembolsos: "",
        condicion: "",
        tiempoCumplimiento: "",
        formaOperar: "",
        usuarioCreacion: "admin",
        estadosId: 1
      };

      //console.log( this.datosCondicionDesembolso);
      this.obtenerDatosItem(proyectoId);
      this.obtenerCondicionesDesembolsos(proyectoId);
      this.obtenerTiposCondicionesDesembolso();
    },

    //Obtener la información de fechas
    async obtenerDatosItem(idProyecto) {
      this.datosProyecto = {};
      this.skeletonLoading = true;

      this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          seccion: "PlazosProyecto",
          id: idProyecto,
        })
        .then(res => {
          if (res.status === 200) {
            this.datosProyecto = res.data;

            //Bloquear las secciones si es usuario externo y si el proyecto no está en estado registro
            if (this.datosProyecto.estadoProyectoId != 1) {
              this.seccionesBloqueadas = true;
            } else {
              this.seccionesBloqueadas = false;
            }

            if (this.confirmacion) {
              this.seccionesBloqueadas = true;
            }

            //this.cuentaCondicionDesembolsoSeleccionada.id = this.datosItem.condicionesDesembolsos;

            if (this.datosProyecto.fechaSuscripcionPlazos) {
              //this.fechaSuscripcionPlazos = moment(this.datosProyecto.fechaSuscripcionPlazos, "YYYY-MM-DD").format("DD-MM-YYYY")

              if (this.datosProyecto.fechaSuscripcionPlazos === "01/01/0001") {
                this.dateFechaSuscripcion = null;
              } else {
                this.dateFechaSuscripcion = moment(
                  this.datosProyecto.fechaSuscripcionPlazos,
                  "DD-MM-YYYY"
                ).format("YYYY-MM-DD");
              }
            } else {
              this.dateFechaSuscripcion = null;
            }

            if (this.datosProyecto.fechaInicioPlazos) {
              //this.fechaInicioPlazos = moment(this.datosProyecto.fechaInicioPlazos, "YYYY-MM-DD").format("DD-MM-YYYY")
              if (this.datosProyecto.fechaInicioPlazos === "01/01/0001") {
                this.dateFechaInicio = null;
              } else {
                this.dateFechaInicio = moment(
                  this.datosProyecto.fechaInicioPlazos,
                  "DD-MM-YYYY"
                ).format("YYYY-MM-DD");
              }
            } else {
              this.dateFechaInicio = null;
            }

            if (this.datosProyecto.fechaAprobacionPlazos) {
              if (this.datosProyecto.fechaAprobacionPlazos === "01/01/0001") {
                this.dateFechaAprobacion = null;
              } else {
                this.dateFechaAprobacion = moment(
                  this.datosProyecto.fechaAprobacionPlazos,
                  "DD-MM-YYYY"
                ).format("YYYY-MM-DD");
              }
            } else {
              this.dateFechaAprobacion = null;
            }

            if (this.datosProyecto.fechaFinalizacionFaseEjecucion) {
              //this.fechaInicioPlazos = moment(this.datosProyecto.fechaInicioPlazos, "YYYY-MM-DD").format("DD-MM-YYYY")

              if (
                this.datosProyecto.fechaFinalizacionFaseEjecucion ===
                "01/01/0001"
              ) {
                this.dateFechaAprobacion = null;
              } else {
                this.dateFechaFinalizacionEjecucion = moment(
                  this.datosProyecto.fechaFinalizacionFaseEjecucion,
                  "DD-MM-YYYY"
                ).format("YYYY-MM-DD");
              }
            } else {
              this.dateFechaFinalizacionEjecucion = null;
            }

            if (this.datosProyecto.fechaFinalizacionPlazos) {
              //this.fechaInicioPlazos = moment(this.datosProyecto.fechaInicioPlazos, "YYYY-MM-DD").format("DD-MM-YYYY")

              if (this.datosProyecto.fechaFinalizacionPlazos === "01/01/0001") {
                this.dateFechaFinalizacion = null;
              } else {
                this.dateFechaFinalizacion = moment(
                  this.datosProyecto.fechaFinalizacionPlazos,
                  "DD-MM-YYYY"
                ).format("YYYY-MM-DD");
              }
            } else {
              this.dateFechaFinalizacion = null;
            }

            this.skeletonLoading = false;

            //console.log(this.datosProyecto.condicionesDesembolsos)

            //Validar si existen enmiendas registradas
            if (this.datosProyecto.enmiendasFechas === 1) {
              this.enmiendaRegistrada = true;
              this.fechaFaseEjecucionActual = this.datosProyecto.fechaFaseEjecucionNueva;
              this.fechaFinalizacionActual = this.datosProyecto.fechaFinalizacionNueva;
            }

            //Validar que el tipo de cooperación sea "Cooperación Financiera"
            if (this.datosProyecto.tiposCooperacionId === 1) {
              this.habilitarSeccionCondicionesDes = true;
              //Validar que tenga habilitado "Cuenta con condiciones de desembolso"
              if (this.datosProyecto.condicionesDesembolsos) {
                this.cuentaCondicionDesembolsoSeleccionada = this.condicionesDesembolsosItems.find(
                  item => item.id === this.datosProyecto.condicionesDesembolsos
                );

                if (this.cuentaCondicionDesembolsoSeleccionada.id === 1) {
                  this.habilitarCondicionesDesembolsos = true;
                } else {
                  this.habilitarCondicionesDesembolsos = false;
                  this.datosFormaOperar = {
                    proyectosCNSId: this.id,
                    detalleFormaOperar: this.datosProyecto.detalleFormaOperar,
                  };
                }
              } else {
                this.cuentaCondicionDesembolsoSeleccionada.id = 2;
              }
            } //Si no es tipo financiera, deshabilitar la sección
            else {
              this.habilitarCondicionesDesembolsos = false;
              this.habilitarSeccionCondicionesDes = false;
              this.cuentaCondicionDesembolsoSeleccionada.id = 2;
              this.actualizarCuentaCondicionesDesembolso();
            }

            //console.log(this.cuentaCondicionDesembolsoSeleccionada.id)
            //this.habilitarCondicionesDesembolsos = true; condicionesDesembolsos
          }
        })
        .catch(() => {
          this.skeletonLoading = false;
        });
    },

    //Mostrar u ocultar la sección de condiciones de desembolosos: accion 1 habilitar, accion 2 deshabilitar
    habilitarSeccionCondicionesDesembolsos(accion) {
      accion === 1
        ? (this.habilitarSeccionCondicionesDes = true)
        : (this.habilitarSeccionCondicionesDes = false);
    },

    //Obtener el listado de condiciones de desembolsos registradas
    async obtenerCondicionesDesembolsos(proyectoId) {
      this.condicionesDesembolsos = [];
      this.tableCondicionesLoading = true;

      this.$store
        .dispatch(OBTENER_CONDICIONES_DESEMBOLSOS, {
          estadoId: 1,
          proyectoId: proyectoId,
        })
        .then(res => {
          if (res.status === 200) {
            this.condicionesDesembolsos = this.$store.state.condiciondesembolso.condicionesDesembolsos;
            if (this.condicionesDesembolsos.length > 0) {
              this.cuentaCondicionDesembolsoSeleccionada.id = 1;
              this.cuentaCondicionDesembolsoSeleccionada.text = "SI";
            } else {
              this.cuentaCondicionDesembolsoSeleccionada.id = 2;
              this.cuentaCondicionDesembolsoSeleccionada.text = "NO";
            }
          }
          //console.log(this.$store.state.condiciondesembolso.condicionesDesembolsos)
          this.tableCondicionesLoading = false;
        })
        .catch(() => {
          this.tableCondicionesLoading = false;
        });
    },

    //Obtener los tipos de condiciones de desembolso (Condicionado por)
    async obtenerTiposCondicionesDesembolso() {
      //  this.sectoresBeneficiadosLoading=true;
      this.tiposCondicionesDesembolso = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TipoCondicionDesembolso/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.tiposCondicionesDesembolso = res.data;
          }
          //this.sectoresBeneficiadosLoading=false;
        })
        .catch(() => {
          this.tiposCondicionesDesembolso = [];
          // this.sectoresBeneficiadosLoading=false;
        });
    },

    async agregarFechas() {
      this.btnRegistroLoading = true;
      //this.cuentaCondicionDesembolsoSeleccionada.id === 2 ? this.datosItem.condicionesDesembolsos.id = 0 : this.datosItem.condicionesDesembolsos.id === 1;

      /*this.datosFechas.fechaSuscripcionPlazos = moment(this.fechaSuscripcionPlazos, "DD-MM-YYYY").format("YYYY-MM-DD");
            this.datosFechas.fechaInicioPlazos = moment(this.fechaInicioPlazos, "DD-MM-YYYY").format("YYYY-MM-DD");
            this.datosFechas.fechaAprobacionPlazos = moment(this.fechaAprobacionPlazos, "DD-MM-YYYY").format("YYYY-MM-DD");
            this.datosFechas.fechaFinalizacionFaseEjecucion = moment(this.fechaFinalizacionFaseEjecucion, "DD-MM-YYYY").format("YYYY-MM-DD");
            this.datosFechas.fechaFinalizacionPlazos = moment(this.fechaFinalizacionPlazos, "DD-MM-YYYY").format("YYYY-MM-DD");
            */

      this.datosFechas.fechaSuscripcionPlazos = moment(
        this.computedFechaSuscripcion,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      this.datosFechas.fechaInicioPlazos = moment(
        this.computedFechaInicio,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      this.datosFechas.fechaAprobacionPlazos = moment(
        this.computedFechaAprobacion,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      this.datosFechas.fechaFinalizacionFaseEjecucion = moment(
        this.computedFechaFinalizacionEjecucion,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      this.datosFechas.fechaFinalizacionPlazos = moment(
        this.computedFechaFinalizacionPlazos,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      await this.$store
        .dispatch(REGISTRAR_PROYECTO_CNS_EXTERNO, {
          datos: this.datosFechas,
          seccion: "FechasPlazos",
          id: this.id
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.btnRegistroLoading = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroLoading = false;
        });

      this.btnRegistroLoading = false;
    },

    async actualizarCuentaCondicionesDesembolso(
      tipo,
      proyectoId,
      cuentaCondicionesId
    ) {
      this.cuentaDesembolsosLoading = true;
      if (tipo === 1) {
        this.datosCuentaDesembolsos.proyectosCNSId = this.id;
        this.cuentaCondicionDesembolsoSeleccionada.id === 2
          ? (this.datosCuentaDesembolsos.condicionesDesembolsos = 2)
          : (this.datosCuentaDesembolsos.condicionesDesembolsos = 1);
      } else {
        this.datosCuentaDesembolsos.proyectosCNSId = proyectoId;
        this.datosCuentaDesembolsos.condicionesDesembolsos = cuentaCondicionesId;
      }

      await this.$store
        .dispatch(ACTUALIZAR_CUENTA_CONDICIONES_DESEMBOLSO_CNS, {
          id: this.id,
          datos: this.datosCuentaDesembolsos
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          //console.log(res)
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            if (this.cuentaCondicionDesembolsoSeleccionada.id === 2) {
              this.habilitarCondicionesDesembolsos = false;
            } else {
              this.habilitarCondicionesDesembolsos = true;
            }
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.habilitarCondicionesDesembolsos = false;
          }
          this.cuentaDesembolsosLoading = false;
        })
        .catch(error => {
          this.cuentaDesembolsosLoading = false;
          if (this.cuentaCondicionDesembolsoSeleccionada.id === 2) {
            this.habilitarCondicionesDesembolsos = false;
          } else {
            this.habilitarCondicionesDesembolsos = true;
          }
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    async agregarCondicionesDesembolsos() {
      this.btnRegistroCondicionesLoading = true;
      await this.$store
        .dispatch(REGISTRAR_CONDICION_DESEMBOLSO, this.datosCondicionDesembolso)
        .then(res => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerCondicionesDesembolsos(this.id);
            this.resetForm();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroCondicionesLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.btnRegistroCondicionesLoading = false;
        });
    },

    //Eliminar una condición de desembolso
    async eliminarCondicionDesembolso(id) {
      this.btnEliminarCondicionDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_CONDICION_DESEMBOLSO, id)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerCondicionesDesembolsos(this.id);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarCondicionDisabled = false;
        })
        .catch(error => {
          this.btnEliminarCondicionDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    async registrarFormaOperar() {
      this.btnFormaOperar = true;

      await this.$store
        .dispatch(ACTUALIZA_FORMA_OPERAR_CNS, { datos: this.datosFormaOperar })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            //this.obtenerCondicionesDesembolsos(this.id);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnFormaOperar = false;
        })
        .catch(error => {
          this.btnFormaOperar = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    resetForm() {
      this.datosCondicionDesembolso = {
        proyectosCNSId: this.id,
        tipoCondicionDesembolsoId: 0,
        numeroDesembolsos: "",
        condicion: "",
        tiempoCumplimiento: "",
        formaOperar: "",
        usuarioCreacion: "admin",
        estadosId: 1,
      };
    },

    formatDatePicker(date) {
      //console.log(date)
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },

  created() {
    this.cuentaCondicionDesembolsoSeleccionada.id = 2;
    this.cuentaCondicionDesembolsoSeleccionada.text = "NO";
    this.resetItems(this.id);
  },
  mounted() {
    /*this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Inicio", route: "dashboard" },
        { title: "Administracion" },
        { title: "Instituciones" }
        ]);*/
  },

  computed: {
    computedFechaSuscripcion: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaSuscripcion);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      },
    },
    computedFechaInicio: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaInicio);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      },
    },
    computedFechaAprobacion: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaAprobacion);
      },
      set(newValue) {
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      },
    },
    computedFechaFinalizacionEjecucion: {
      get() {
        //console.log("asdf")

        return this.formatDatePicker(this.dateFechaFinalizacionEjecucion);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      },
    },
    computedFechaFinalizacionPlazos: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaFinalizacion);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      },
    },

    headersCondiciones() {
      return [
        {
          text: "Condicionado por",
          align: "start",
          sortable: false,
          value: "condicionadoPor",
        },
        {
          text: "Número de desembolsos",
          align: "start",
          sortable: true,
          value: "numeroDesembolsos",
        },
        {
          text: "Condición",
          align: "start",
          sortable: true,
          value: "condicion",
        },
        {
          text: "Tiempo de cumplimiento",
          align: "start",
          sortable: true,
          value: "tiempoCumplimiento",
        },
        /* {
                    text: "Forma de operar",
                    align: "start",
                    sortable: true,
                    value: "formaOperar"
                }, */
        {
          text: "Acción",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
      ];
    },
  },
};
</script>
